// Fonts Size

$font-7: 0.365vw;
$font-8: 0.417vw;
$font-9: 0.469vw;
$font-10: 0.521vw;
$font-11: 0.573vw;
$font-12: 0.625vw;
$font-13: 0.677vw;
$font-14: 0.729vw;
$font-15: 0.781vw;
$font-16: 0.833vw;
$font-17: 0.885vw;
$font-18: 0.938vw;
$font-19: 0.990vw;
$font-20: 1.042vw;
$font-21: 1.094vw;
$font-22: 1.146vw;
$font-23: 1.198vw;
$font-24: 1.250vw;
$font-25: 1.302vw;
$font-26: 1.354vw;
$font-27: 1.406vw;
$font-28: 1.458vw;
$font-29: 1.510vw;
$font-30: 1.563vw;
$font-31: 1.615vw;
$font-32: 1.667vw;
$font-33: 1.719vw;
$font-34: 1.771vw;
$font-35: 1.823vw;
$font-36: 1.875vw;
$font-37: 1.927vw;
$font-38: 1.979vw;
$font-39: 2.031vw;
$font-40: 2.083vw;
$font-41: 2.135vw;
$font-42: 2.188vw;
$font-43: 2.240vw;
$font-44: 2.292vw;
$font-45: 2.344vw;
$font-46: 2.396vw;
$font-47: 2.448vw;
$font-48: 2.500vw;
$font-49: 2.552vw;
$font-50: 2.604vw;
$font-51: 2.656vw;
$font-52: 2.708vw;
$font-53: 2.760vw;
$font-54: 2.813vw;
$font-55: 2.865vw;
$font-56: 2.917vw;
$font-57: 2.969vw;
$font-58: 3.021vw;
$font-59: 3.073vw;
$font-60: 3.125vw;
$font-61: 3.177vw;
$font-62: 3.229vw;
$font-63: 3.281vw;
$font-64: 3.333vw;
$font-65: 3.385vw;
$font-66: 3.438vw;
$font-67: 3.490vw;
$font-68: 3.542vw;
$font-69: 3.594vw;
$font-70: 3.646vw;
$font-71: 3.698vw;
$font-72: 3.750vw;
$font-73: 3.802vw;
$font-74: 3.854vw;
$font-75: 3.906vw;

// Font Weight 

$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-350: 350;
$fw-400: 400;
$fw-450: 450;
$fw-500: 500;
$fw-550: 550;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;
$fw-bold: bold;
$fw-bolder: bolder;
$fw-normal: normal;

// Positions

$position-relative: relative;
$position-absolute: absolute;
$position-fixed: fixed;
$position-sticky: sticky;
$position-inherit: inherit;
$position-initial: initial;
$position-static: static;

// Top
$top:0;
$left:0;
$right:0;
$bottom:0;
//Display 

$display-inline: inline;
$display-inline-block: inline-block;
$display-flex: flex;
$display-inline-flex: inline-flex;
$display-inline-flexbox: inline-flexbox;
$display-block: block;
$display-none: none;
$display-inherit: inherit;
$display-grid: grid;
$display-inline-grid: inline-grid;
$diplay-table: table;
$diplay-table-cell: table-cell;
$display-flow-root: flow-root;
$display-contents: contents;
$display-table-row: table-row;

//  Alignment

$start: start;
$center: center;
$end: end;
$justify: justify;
$space-between: space-between;
$space-around: space-around;
$space-evenly: space-evenly;

// Overflow

$overflow-hidden: hidden;
$overflow-auto: auto;
$overflow-visible: visible;



// Font Style

$font-style-normal: normal;
$font-style-italic: italic;

//****** Width *******

$height-width-100: 100%;


// Border

$border-none: none;

// Margin
 
$margin-padding-0: 0;
$margin-auto: auto;

// flex-direction

$flex-direction-row: row;
$flex-direction-row-reverse: row-reverse;
$flex-direction-column: column;
$flex-direction-column-reverse: column-reverse;
$flex-direction-inherit: inherit;
$flex-direction-initial: initial;
$flex-direction-revert: revert;
$flex-direction-unset: unset;



// fle-wrap

$flex-wrap-inherit: inherit;
$flex-wrap-initial: initial;
$flex-wrap-nowrap: nowrap;
$flex-wrap-revert: revert;
$flex-wrap-unset: unset;
$flex-wrap-wrap: wrap;
$flex-wrap-wrap-reverse: wrap-reverse;
