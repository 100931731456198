@keyframes shift-btn-blink {
    0% {
      transform: rotate(2deg);
      box-shadow: 0 0 0 0 rgb(200 200 200), 0 0 0 0 rgb(220 220 220);
    }
  
    20% {
      transform: rotate(-2deg);
  
    }
  
    40% {
      transform: rotate(2deg);
      box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(162 168 174);
    }
  
    60% {
      transform: rotate(-2deg);
    }
  
    80% {
      transform: rotate(2deg);
      box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
    }
  
    100% {
      transform: rotate(-2deg);
      box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
    }
  }
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }