/* GRID CLASSES START */
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs,
.col-xs-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-exl-1,
.col-exl-2,
.col-exl-3,
.col-exl-4,
.col-exl-5,
.col-exl-6,
.col-exl-7,
.col-exl-8,
.col-exl-9,
.col-exl-10,
.col-exl-11,
.col-exl-12,
.col-exl,
.col-exl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.col-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 450px) {
    .col-xs {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    
    .col-xs-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    
    .col-xs-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    
    .col-xs-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    
    .col-xs-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .col-xs-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    
    .col-xs-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    
    .col-xs-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .col-xs-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    
    .col-xs-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    
    .col-xs-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    
    .col-xs-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    
    .col-xs-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    
    .col-xs-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    
    .col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    
    .col-sm-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    
    .col-sm-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    
    .col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .col-sm-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    
    .col-sm-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    
    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .col-sm-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    
    .col-sm-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    
    .col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    
    .col-sm-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    
    .col-sm-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    
    .col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    
    .col-md-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    
    .col-md-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .col-md-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    
    .col-md-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .col-md-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    
    .col-md-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    
    .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    
    .col-md-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    
    .col-md-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    
    .col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    
    .col-lg-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    
    .col-lg-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    
    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .col-lg-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    
    .col-lg-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .col-lg-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    
    .col-lg-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    
    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    
    .col-lg-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    
    .col-lg-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    
    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    
    .col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    
    .col-xl-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    
    .col-xl-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    
    .col-xl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .col-xl-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    
    .col-xl-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    
    .col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .col-xl-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    
    .col-xl-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    
    .col-xl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    
    .col-xl-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    
    .col-xl-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    
    .col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 1441px) {
    .col-exl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    
    .col-exl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    
    .col-exl-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    
    .col-exl-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    
    .col-exl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .col-exl-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    
    .col-exl-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    
    .col-exl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .col-exl-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    
    .col-exl-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    
    .col-exl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    
    .col-exl-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    
    .col-exl-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    
    .col-exl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
/* GRID CLASSES END */

/* PADDING CLASSES START */
.p-auto {
    padding: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.py-auto,
.pt-auto {
    padding-top: auto !important;
}

.py-0,
.pt-0 {
    padding-top: 0 !important;
}

.py-1,
.pt-1 {
    padding-top: 0.25rem !important;
}

.py-2,
.pt-2 {
    padding-top: 0.5rem !important;
}

.py-3,
.pt-3 {
    padding-top: 1rem !important;
}

.py-4,
.pt-4 {
    padding-top: 1.5rem !important;
}

.py-5,
.pt-5 {
    padding-top: 3rem !important;
}

.py-auto,
.pb-auto {
    padding-bottom: auto !important;
}

.py-0,
.pb-0 {
    padding-bottom: 0 !important;
}

.py-1,
.pb-1 {
    padding-bottom: 0.25rem !important;
}

.py-2,
.pb-2 {
    padding-bottom: 0.5rem !important;
}

.py-3,
.pb-3 {
    padding-bottom: 1rem !important;
}

.py-4,
.pb-4 {
    padding-bottom: 1.5rem !important;
}

.py-5,
.pb-5 {
    padding-bottom: 3rem !important;
}

.px-auto,
.pr-auto {
    padding-right: auto !important;
}

.px-0,
.pr-0 {
    padding-right: 0 !important;
}

.px-1,
.pr-1 {
    padding-right: 0.25rem !important;
}

.px-2,
.pr-2 {
    padding-right: 0.5rem !important;
}

.px-3,
.pr-3 {
    padding-right: 1rem !important;
}

.px-4,
.pr-4 {
    padding-right: 1.5rem !important;
}

.px-5,
.pr-5 {
    padding-right: 3rem !important;
}

.px-auto,
.pl-auto {
    padding-left: auto !important;
}

.px-0,
.pl-0 {
    padding-left: 0 !important;
}

.px-1,
.pl-1 {
    padding-left: 0.25rem !important;
}

.px-2,
.pl-2 {
    padding-left: 0.5rem !important;
}

.px-3,
.pl-3 {
    padding-left: 1rem !important;
}

.px-4,
.pl-4 {
    padding-left: 1.5rem !important;
}

.px-5,
.pl-5 {
    padding-left: 3rem !important;
}

@media (min-width: 450px) {
    .p-xs-auto {
        padding: auto !important;
    }
    
    .p-xs-0 {
        padding: 0 !important;
    }
    
    .p-xs-1 {
        padding: 0.25rem !important;
    }
    
    .p-xs-2 {
        padding: 0.5rem !important;
    }
    
    .p-xs-3 {
        padding: 1rem !important;
    }
    
    .p-xs-4 {
        padding: 1.5rem !important;
    }
    
    .p-xs-5 {
        padding: 3rem !important;
    }
    
    .py-xs-auto,
    .pt-xs-auto {
        padding-top: auto !important;
    }
    
    .py-xs-0,
    .pt-xs-0 {
        padding-top: 0 !important;
    }
    
    .py-xs-1,
    .pt-xs-1 {
        padding-top: 0.25rem !important;
    }
    
    .py-xs-2,
    .pt-xs-2 {
        padding-top: 0.5rem !important;
    }
    
    .py-xs-3,
    .pt-xs-3 {
        padding-top: 1rem !important;
    }
    
    .py-xs-4,
    .pt-xs-4 {
        padding-top: 1.5rem !important;
    }
    
    .py-xs-5,
    .pt-xs-5 {
        padding-top: 3rem !important;
    }
    
    .py-xs-auto,
    .pb-xs-auto {
        padding-bottom: auto !important;
    }
    
    .py-xs-0,
    .pb-xs-0 {
        padding-bottom: 0 !important;
    }
    
    .py-xs-1,
    .pb-xs-1 {
        padding-bottom: 0.25rem !important;
    }
    
    .py-xs-2,
    .pb-xs-2 {
        padding-bottom: 0.5rem !important;
    }
    
    .py-xs-3,
    .pb-xs-3 {
        padding-bottom: 1rem !important;
    }
    
    .py-xs-4,
    .pb-xs-4 {
        padding-bottom: 1.5rem !important;
    }
    
    .py-xs-5,
    .pb-xs-5 {
        padding-bottom: 3rem !important;
    }
    
    .px-xs-auto,
    .pr-xs-auto {
        padding-right: auto !important;
    }
    
    .px-xs-0,
    .pr-xs-0 {
        padding-right: 0 !important;
    }
    
    .px-xs-1,
    .pr-xs-1 {
        padding-right: 0.25rem !important;
    }
    
    .px-xs-2,
    .pr-xs-2 {
        padding-right: 0.5rem !important;
    }
    
    .px-xs-3,
    .pr-xs-3 {
        padding-right: 1rem !important;
    }
    
    .px-xs-4,
    .pr-xs-4 {
        padding-right: 1.5rem !important;
    }
    
    .px-xs-5,
    .pr-xs-5 {
        padding-right: 3rem !important;
    }
    
    .px-xs-auto,
    .pl-xs-auto {
        padding-left: auto !important;
    }
    
    .px-xs-0,
    .pl-xs-0 {
        padding-left: 0 !important;
    }
    
    .px-xs-1,
    .pl-xs-1 {
        padding-left: 0.25rem !important;
    }
    
    .px-xs-2,
    .pl-xs-2 {
        padding-left: 0.5rem !important;
    }
    
    .px-xs-3,
    .pl-xs-3 {
        padding-left: 1rem !important;
    }
    
    .px-xs-4,
    .pl-xs-4 {
        padding-left: 1.5rem !important;
    }
    
    .px-xs-5,
    .pl-xs-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 576px) {
    .p-sm-auto {
        padding: auto !important;
    }
    
    .p-sm-0 {
        padding: 0 !important;
    }
    
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    
    .p-sm-3 {
        padding: 1rem !important;
    }
    
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    
    .p-sm-5 {
        padding: 3rem !important;
    }
    
    .py-sm-auto,
    .pt-sm-auto {
        padding-top: auto !important;
    }
    
    .py-sm-0,
    .pt-sm-0 {
        padding-top: 0 !important;
    }
    
    .py-sm-1,
    .pt-sm-1 {
        padding-top: 0.25rem !important;
    }
    
    .py-sm-2,
    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }
    
    .py-sm-3,
    .pt-sm-3 {
        padding-top: 1rem !important;
    }
    
    .py-sm-4,
    .pt-sm-4 {
        padding-top: 1.5rem !important;
    }
    
    .py-sm-5,
    .pt-sm-5 {
        padding-top: 3rem !important;
    }
    
    .py-sm-auto,
    .pb-sm-auto {
        padding-bottom: auto !important;
    }
    
    .py-sm-0,
    .pb-sm-0 {
        padding-bottom: 0 !important;
    }
    
    .py-sm-1,
    .pb-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    
    .py-sm-2,
    .pb-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    
    .py-sm-3,
    .pb-sm-3 {
        padding-bottom: 1rem !important;
    }
    
    .py-sm-4,
    .pb-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    
    .py-sm-5,
    .pb-sm-5 {
        padding-bottom: 3rem !important;
    }
    
    .px-sm-auto,
    .pr-sm-auto {
        padding-right: auto !important;
    }
    
    .px-sm-0,
    .pr-sm-0 {
        padding-right: 0 !important;
    }
    
    .px-sm-1,
    .pr-sm-1 {
        padding-right: 0.25rem !important;
    }
    
    .px-sm-2,
    .pr-sm-2 {
        padding-right: 0.5rem !important;
    }
    
    .px-sm-3,
    .pr-sm-3 {
        padding-right: 1rem !important;
    }
    
    .px-sm-4,
    .pr-sm-4 {
        padding-right: 1.5rem !important;
    }
    
    .px-sm-5,
    .pr-sm-5 {
        padding-right: 3rem !important;
    }
    
    .px-sm-auto,
    .pl-sm-auto {
        padding-left: auto !important;
    }
    
    .px-sm-0,
    .pl-sm-0 {
        padding-left: 0 !important;
    }
    
    .px-sm-1,
    .pl-sm-1 {
        padding-left: 0.25rem !important;
    }
    
    .px-sm-2,
    .pl-sm-2 {
        padding-left: 0.5rem !important;
    }
    
    .px-sm-3,
    .pl-sm-3 {
        padding-left: 1rem !important;
    }
    
    .px-sm-4,
    .pl-sm-4 {
        padding-left: 1.5rem !important;
    }
    
    .px-sm-5,
    .pl-sm-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 768px) {
    .p-md-auto {
        padding: auto !important;
    }
    
    .p-md-0 {
        padding: 0 !important;
    }
    
    .p-md-1 {
        padding: 0.25rem !important;
    }
    
    .p-md-2 {
        padding: 0.5rem !important;
    }
    
    .p-md-3 {
        padding: 1rem !important;
    }
    
    .p-md-4 {
        padding: 1.5rem !important;
    }
    
    .p-md-5 {
        padding: 3rem !important;
    }
    
    .py-md-auto,
    .pt-md-auto {
        padding-top: auto !important;
    }
    
    .py-md-0,
    .pt-md-0 {
        padding-top: 0 !important;
    }
    
    .py-md-1,
    .pt-md-1 {
        padding-top: 0.25rem !important;
    }
    
    .py-md-2,
    .pt-md-2 {
        padding-top: 0.5rem !important;
    }
    
    .py-md-3,
    .pt-md-3 {
        padding-top: 1rem !important;
    }
    
    .py-md-4,
    .pt-md-4 {
        padding-top: 1.5rem !important;
    }
    
    .py-md-5,
    .pt-md-5 {
        padding-top: 3rem !important;
    }
    
    .py-md-auto,
    .pb-md-auto {
        padding-bottom: auto !important;
    }
    
    .py-md-0,
    .pb-md-0 {
        padding-bottom: 0 !important;
    }
    
    .py-md-1,
    .pb-md-1 {
        padding-bottom: 0.25rem !important;
    }
    
    .py-md-2,
    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }
    
    .py-md-3,
    .pb-md-3 {
        padding-bottom: 1rem !important;
    }
    
    .py-md-4,
    .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
    
    .py-md-5,
    .pb-md-5 {
        padding-bottom: 3rem !important;
    }
    
    .px-md-auto,
    .pr-md-auto {
        padding-right: auto !important;
    }
    
    .px-md-0,
    .pr-md-0 {
        padding-right: 0 !important;
    }
    
    .px-md-1,
    .pr-md-1 {
        padding-right: 0.25rem !important;
    }
    
    .px-md-2,
    .pr-md-2 {
        padding-right: 0.5rem !important;
    }
    
    .px-md-3,
    .pr-md-3 {
        padding-right: 1rem !important;
    }
    
    .px-md-4,
    .pr-md-4 {
        padding-right: 1.5rem !important;
    }
    
    .px-md-5,
    .pr-md-5 {
        padding-right: 3rem !important;
    }
    
    .px-md-auto,
    .pl-md-auto {
        padding-left: auto !important;
    }
    
    .px-md-0,
    .pl-md-0 {
        padding-left: 0 !important;
    }
    
    .px-md-1,
    .pl-md-1 {
        padding-left: 0.25rem !important;
    }
    
    .px-md-2,
    .pl-md-2 {
        padding-left: 0.5rem !important;
    }
    
    .px-md-3,
    .pl-md-3 {
        padding-left: 1rem !important;
    }
    
    .px-md-4,
    .pl-md-4 {
        padding-left: 1.5rem !important;
    }
    
    .px-md-5,
    .pl-md-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 992px) {
    .p-lg-auto {
        padding: auto !important;
    }
    
    .p-lg-0 {
        padding: 0 !important;
    }
    
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    
    .p-lg-3 {
        padding: 1rem !important;
    }
    
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    
    .p-lg-5 {
        padding: 3rem !important;
    }
    
    .py-lg-auto,
    .pt-lg-auto {
        padding-top: auto !important;
    }
    
    .py-lg-0,
    .pt-lg-0 {
        padding-top: 0 !important;
    }
    
    .py-lg-1,
    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }
    
    .py-lg-2,
    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }
    
    .py-lg-3,
    .pt-lg-3 {
        padding-top: 1rem !important;
    }
    
    .py-lg-4,
    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }
    
    .py-lg-5,
    .pt-lg-5 {
        padding-top: 3rem !important;
    }
    
    .py-lg-auto,
    .pb-lg-auto {
        padding-bottom: auto !important;
    }
    
    .py-lg-0,
    .pb-lg-0 {
        padding-bottom: 0 !important;
    }
    
    .py-lg-1,
    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    
    .py-lg-2,
    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    
    .py-lg-3,
    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }
    
    .py-lg-4,
    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    
    .py-lg-5,
    .pb-lg-5 {
        padding-bottom: 3rem !important;
    }
    
    .px-lg-auto,
    .pr-lg-auto {
        padding-right: auto !important;
    }
    
    .px-lg-0,
    .pr-lg-0 {
        padding-right: 0 !important;
    }
    
    .px-lg-1,
    .pr-lg-1 {
        padding-right: 0.25rem !important;
    }
    
    .px-lg-2,
    .pr-lg-2 {
        padding-right: 0.5rem !important;
    }
    
    .px-lg-3,
    .pr-lg-3 {
        padding-right: 1rem !important;
    }
    
    .px-lg-4,
    .pr-lg-4 {
        padding-right: 1.5rem !important;
    }
    
    .px-lg-5,
    .pr-lg-5 {
        padding-right: 3rem !important;
    }
    
    .px-lg-auto,
    .pl-lg-auto {
        padding-left: auto !important;
    }
    
    .px-lg-0,
    .pl-lg-0 {
        padding-left: 0 !important;
    }
    
    .px-lg-1,
    .pl-lg-1 {
        padding-left: 0.25rem !important;
    }
    
    .px-lg-2,
    .pl-lg-2 {
        padding-left: 0.5rem !important;
    }
    
    .px-lg-3,
    .pl-lg-3 {
        padding-left: 1rem !important;
    }
    
    .px-lg-4,
    .pl-lg-4 {
        padding-left: 1.5rem !important;
    }
    
    .px-lg-5,
    .pl-lg-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .p-xl-auto {
        padding: auto !important;
    }
    
    .p-xl-0 {
        padding: 0 !important;
    }
    
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    
    .p-xl-3 {
        padding: 1rem !important;
    }
    
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    
    .p-xl-5 {
        padding: 3rem !important;
    }
    
    .py-xl-auto,
    .pt-xl-auto {
        padding-top: auto !important;
    }
    
    .py-xl-0,
    .pt-xl-0 {
        padding-top: 0 !important;
    }
    
    .py-xl-1,
    .pt-xl-1 {
        padding-top: 0.25rem !important;
    }
    
    .py-xl-2,
    .pt-xl-2 {
        padding-top: 0.5rem !important;
    }
    
    .py-xl-3,
    .pt-xl-3 {
        padding-top: 1rem !important;
    }
    
    .py-xl-4,
    .pt-xl-4 {
        padding-top: 1.5rem !important;
    }
    
    .py-xl-5,
    .pt-xl-5 {
        padding-top: 3rem !important;
    }
    
    .py-xl-auto,
    .pb-xl-auto {
        padding-bottom: auto !important;
    }
    
    .py-xl-0,
    .pb-xl-0 {
        padding-bottom: 0 !important;
    }
    
    .py-xl-1,
    .pb-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    
    .py-xl-2,
    .pb-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    
    .py-xl-3,
    .pb-xl-3 {
        padding-bottom: 1rem !important;
    }
    
    .py-xl-4,
    .pb-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    
    .py-xl-5,
    .pb-xl-5 {
        padding-bottom: 3rem !important;
    }
    
    .px-xl-auto,
    .pr-xl-auto {
        padding-right: auto !important;
    }

    .px-xl-0,
    .pr-xl-0 {
        padding-right: 0 !important;
    }
    
    .px-xl-1,
    .pr-xl-1 {
        padding-right: 0.25rem !important;
    }
    
    .px-xl-2,
    .pr-xl-2 {
        padding-right: 0.5rem !important;
    }
    
    .px-xl-3,
    .pr-xl-3 {
        padding-right: 1rem !important;
    }
    
    .px-xl-4,
    .pr-xl-4 {
        padding-right: 1.5rem !important;
    }
    
    .px-xl-5,
    .pr-xl-5 {
        padding-right: 3rem !important;
    }
    
    .px-xl-auto,
    .pl-xl-auto {
        padding-left: auto !important;
    }
    
    .px-xl-0,
    .pl-xl-0 {
        padding-left: 0 !important;
    }
    
    .px-xl-1,
    .pl-xl-1 {
        padding-left: 0.25rem !important;
    }
    
    .px-xl-2,
    .pl-xl-2 {
        padding-left: 0.5rem !important;
    }
    
    .px-xl-3,
    .pl-xl-3 {
        padding-left: 1rem !important;
    }
    
    .px-xl-4,
    .pl-xl-4 {
        padding-left: 1.5rem !important;
    }
    
    .px-xl-5,
    .pl-xl-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 1441px) {
    .p-exl-auto {
        padding: auto !important;
    }
    
    .p-exl-0 {
        padding: 0 !important;
    }
    
    .p-exl-1 {
        padding: 0.25rem !important;
    }
    
    .p-exl-2 {
        padding: 0.5rem !important;
    }
    
    .p-exl-3 {
        padding: 1rem !important;
    }
    
    .p-exl-4 {
        padding: 1.5rem !important;
    }
    
    .p-exl-5 {
        padding: 3rem !important;
    }
    
    .py-exl-auto,
    .pt-exl-auto {
        padding-top: auto !important;
    }
    
    .py-exl-0,
    .pt-exl-0 {
        padding-top: 0 !important;
    }
    
    .py-exl-1,
    .pt-exl-1 {
        padding-top: 0.25rem !important;
    }
    
    .py-exl-2,
    .pt-exl-2 {
        padding-top: 0.5rem !important;
    }
    
    .py-exl-3,
    .pt-exl-3 {
        padding-top: 1rem !important;
    }
    
    .py-exl-4,
    .pt-exl-4 {
        padding-top: 1.5rem !important;
    }
    
    .py-exl-5,
    .pt-exl-5 {
        padding-top: 3rem !important;
    }
    
    .py-exl-auto,
    .pb-exl-auto {
        padding-bottom: auto !important;
    }
    
    .py-exl-0,
    .pb-exl-0 {
        padding-bottom: 0 !important;
    }
    
    .py-exl-1,
    .pb-exl-1 {
        padding-bottom: 0.25rem !important;
    }
    
    .py-exl-2,
    .pb-exl-2 {
        padding-bottom: 0.5rem !important;
    }
    
    .py-exl-3,
    .pb-exl-3 {
        padding-bottom: 1rem !important;
    }
    
    .py-exl-4,
    .pb-exl-4 {
        padding-bottom: 1.5rem !important;
    }
    
    .py-exl-5,
    .pb-exl-5 {
        padding-bottom: 3rem !important;
    }
    
    .px-exl-auto,
    .pr-exl-auto {
        padding-right: auto !important;
    }
    
    .px-exl-0,
    .pr-exl-0 {
        padding-right: 0 !important;
    }
    
    .px-exl-1,
    .pr-exl-1 {
        padding-right: 0.25rem !important;
    }
    
    .px-exl-2,
    .pr-exl-2 {
        padding-right: 0.5rem !important;
    }
    
    .px-exl-3,
    .pr-exl-3 {
        padding-right: 1rem !important;
    }
    
    .px-exl-4,
    .pr-exl-4 {
        padding-right: 1.5rem !important;
    }
    
    .px-exl-5,
    .pr-exl-5 {
        padding-right: 3rem !important;
    }
    
    .px-exl-auto,
    .pl-exl-auto {
        padding-left: auto !important;
    }
    
    .px-exl-0,
    .pl-exl-0 {
        padding-left: 0 !important;
    }
    
    .px-exl-1,
    .pl-exl-1 {
        padding-left: 0.25rem !important;
    }
    
    .px-exl-2,
    .pl-exl-2 {
        padding-left: 0.5rem !important;
    }
    
    .px-exl-3,
    .pl-exl-3 {
        padding-left: 1rem !important;
    }
    
    .px-exl-4,
    .pl-exl-4 {
        padding-left: 1.5rem !important;
    }
    
    .px-exl-5,
    .pl-exl-5 {
        padding-left: 3rem !important;
    }
}
/* PADDING CLASSES END */

/* MARGIN CLASSES START */
.m-auto {
    margin: auto !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.my-auto,
.mt-auto {
    margin-top: auto !important;
}

.my-0,
.mt-0 {
    margin-top: 0 !important;
}

.my-1,
.mt-1 {
    margin-top: 0.25rem !important;
}

.my-2,
.mt-2 {
    margin-top: 0.5rem !important;
}

.my-3,
.mt-3 {
    margin-top: 1rem !important;
}

.my-4,
.mt-4 {
    margin-top: 1.5rem !important;
}

.my-5,
.mt-5 {
    margin-top: 3rem !important;
}

.my-auto,
.mb-auto {
    margin-bottom: auto !important;
}

.my-0,
.mb-0 {
    margin-bottom: 0 !important;
}

.my-1,
.mb-1 {
    margin-bottom: 0.25rem !important;
}

.my-2,
.mb-2 {
    margin-bottom: 0.5rem !important;
}

.my-3,
.mb-3 {
    margin-bottom: 1rem !important;
}

.my-4,
.mb-4 {
    margin-bottom: 1.5rem !important;
}

.my-5,
.mb-5 {
    margin-bottom: 3rem !important;
}

.mx-auto,
.mr-auto {
    margin-right: auto !important;
}

.mx-0,
.mr-0 {
    margin-right: 0 !important;
}

.mx-1,
.mr-1 {
    margin-right: 0.25rem !important;
}

.mx-2,
.mr-2 {
    margin-right: 0.5rem !important;
}

.mx-3,
.mr-3 {
    margin-right: 1rem !important;
}

.mx-4,
.mr-4 {
    margin-right: 1.5rem !important;
}

.mx-5,
.mr-5 {
    margin-right: 3rem !important;
}

.mx-auto,
.ml-auto {
    margin-left: auto !important;
}

.mx-0,
.ml-0 {
    margin-left: 0 !important;
}

.mx-1,
.ml-1 {
    margin-left: 0.25rem !important;
}

.mx-2,
.ml-2 {
    margin-left: 0.5rem !important;
}

.mx-3,
.ml-3 {
    margin-left: 1rem !important;
}

.mx-4,
.ml-4 {
    margin-left: 1.5rem !important;
}

.mx-5,
.ml-5 {
    margin-left: 3rem !important;
}

@media (min-width: 450px) {
    .m-xs-auto {
        margin: auto !important;
    }
    
    .m-xs-0 {
        margin: 0 !important;
    }
    
    .m-xs-1 {
        margin: 0.25rem !important;
    }
    
    .m-xs-2 {
        margin: 0.5rem !important;
    }
    
    .m-xs-3 {
        margin: 1rem !important;
    }
    
    .m-xs-4 {
        margin: 1.5rem !important;
    }
    
    .m-xs-5 {
        margin: 3rem !important;
    }
    
    .my-xs-auto,
    .mt-xs-auto {
        margin-top: auto !important;
    }
    
    .my-xs-0,
    .mt-xs-0 {
        margin-top: 0 !important;
    }
    
    .my-xs-1,
    .mt-xs-1 {
        margin-top: 0.25rem !important;
    }
    
    .my-xs-2,
    .mt-xs-2 {
        margin-top: 0.5rem !important;
    }
    
    .my-xs-3,
    .mt-xs-3 {
        margin-top: 1rem !important;
    }
    
    .my-xs-4,
    .mt-xs-4 {
        margin-top: 1.5rem !important;
    }
    
    .my-xs-5,
    .mt-xs-5 {
        margin-top: 3rem !important;
    }
    
    .my-xs-auto,
    .mb-xs-auto {
        margin-bottom: auto !important;
    }
    
    .my-xs-0,
    .mb-xs-0 {
        margin-bottom: 0 !important;
    }
    
    .my-xs-1,
    .mb-xs-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .my-xs-2,
    .mb-xs-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .my-xs-3,
    .mb-xs-3 {
        margin-bottom: 1rem !important;
    }
    
    .my-xs-4,
    .mb-xs-4 {
        margin-bottom: 1.5rem !important;
    }
    
    .my-xs-5,
    .mb-xs-5 {
        margin-bottom: 3rem !important;
    }
    
    .mx-xs-auto,
    .mr-xs-auto {
        margin-right: auto !important;
    }
    
    .mx-xs-0,
    .mr-xs-0 {
        margin-right: 0 !important;
    }
    
    .mx-xs-1,
    .mr-xs-1 {
        margin-right: 0.25rem !important;
    }
    
    .mx-xs-2,
    .mr-xs-2 {
        margin-right: 0.5rem !important;
    }
    
    .mx-xs-3,
    .mr-xs-3 {
        margin-right: 1rem !important;
    }
    
    .mx-xs-4,
    .mr-xs-4 {
        margin-right: 1.5rem !important;
    }
    
    .mx-xs-5,
    .mr-xs-5 {
        margin-right: 3rem !important;
    }
    
    .mx-xs-auto,
    .ml-xs-auto {
        margin-left: auto !important;
    }
    
    .mx-xs-0,
    .ml-xs-0 {
        margin-left: 0 !important;
    }
    
    .mx-xs-1,
    .ml-xs-1 {
        margin-left: 0.25rem !important;
    }
    
    .mx-xs-2,
    .ml-xs-2 {
        margin-left: 0.5rem !important;
    }
    
    .mx-xs-3,
    .ml-xs-3 {
        margin-left: 1rem !important;
    }
    
    .mx-xs-4,
    .ml-xs-4 {
        margin-left: 1.5rem !important;
    }
    
    .mx-xs-5,
    .ml-xs-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 576px) {
    .m-sm-auto {
        margin: auto !important;
    }
    
    .m-sm-0 {
        margin: 0 !important;
    }
    
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    
    .m-sm-3 {
        margin: 1rem !important;
    }
    
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    
    .m-sm-5 {
        margin: 3rem !important;
    }
    
    .my-sm-auto,
    .mt-sm-auto {
        margin-top: auto !important;
    }
    
    .my-sm-0,
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    
    .my-sm-1,
    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }
    
    .my-sm-2,
    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }
    
    .my-sm-3,
    .mt-sm-3 {
        margin-top: 1rem !important;
    }
    
    .my-sm-4,
    .mt-sm-4 {
        margin-top: 1.5rem !important;
    }
    
    .my-sm-5,
    .mt-sm-5 {
        margin-top: 3rem !important;
    }
    
    .my-sm-auto,
    .mb-sm-auto {
        margin-bottom: auto !important;
    }
    
    .my-sm-0,
    .mb-sm-0 {
        margin-bottom: 0 !important;
    }
    
    .my-sm-1,
    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .my-sm-2,
    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .my-sm-3,
    .mb-sm-3 {
        margin-bottom: 1rem !important;
    }
    
    .my-sm-4,
    .mb-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    
    .my-sm-5,
    .mb-sm-5 {
        margin-bottom: 3rem !important;
    }
    
    .mx-sm-auto,
    .mr-sm-auto {
        margin-right: auto !important;
    }
    
    .mx-sm-0,
    .mr-sm-0 {
        margin-right: 0 !important;
    }
    
    .mx-sm-1,
    .mr-sm-1 {
        margin-right: 0.25rem !important;
    }
    
    .mx-sm-2,
    .mr-sm-2 {
        margin-right: 0.5rem !important;
    }
    
    .mx-sm-3,
    .mr-sm-3 {
        margin-right: 1rem !important;
    }
    
    .mx-sm-4,
    .mr-sm-4 {
        margin-right: 1.5rem !important;
    }
    
    .mx-sm-5,
    .mr-sm-5 {
        margin-right: 3rem !important;
    }
    
    .mx-sm-auto,
    .ml-sm-auto {
        margin-left: auto !important;
    }
    
    .mx-sm-0,
    .ml-sm-0 {
        margin-left: 0 !important;
    }
    
    .mx-sm-1,
    .ml-sm-1 {
        margin-left: 0.25rem !important;
    }
    
    .mx-sm-2,
    .ml-sm-2 {
        margin-left: 0.5rem !important;
    }
    
    .mx-sm-3,
    .ml-sm-3 {
        margin-left: 1rem !important;
    }
    
    .mx-sm-4,
    .ml-sm-4 {
        margin-left: 1.5rem !important;
    }
    
    .mx-sm-5,
    .ml-sm-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 768px) {
    .m-md-auto {
        margin: auto !important;
    }
    
    .m-md-0 {
        margin: 0 !important;
    }
    
    .m-md-1 {
        margin: 0.25rem !important;
    }
    
    .m-md-2 {
        margin: 0.5rem !important;
    }
    
    .m-md-3 {
        margin: 1rem !important;
    }
    
    .m-md-4 {
        margin: 1.5rem !important;
    }
    
    .m-md-5 {
        margin: 3rem !important;
    }
    
    .my-md-auto,
    .mt-md-auto {
        margin-top: auto !important;
    }
    
    .my-md-0,
    .mt-md-0 {
        margin-top: 0 !important;
    }
    
    .my-md-1,
    .mt-md-1 {
        margin-top: 0.25rem !important;
    }
    
    .my-md-2,
    .mt-md-2 {
        margin-top: 0.5rem !important;
    }
    
    .my-md-3,
    .mt-md-3 {
        margin-top: 1rem !important;
    }
    
    .my-md-4,
    .mt-md-4 {
        margin-top: 1.5rem !important;
    }
    
    .my-md-5,
    .mt-md-5 {
        margin-top: 3rem !important;
    }
    
    .my-md-auto,
    .mb-md-auto {
        margin-bottom: auto !important;
    }
    
    .my-md-0,
    .mb-md-0 {
        margin-bottom: 0 !important;
    }
    
    .my-md-1,
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .my-md-2,
    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .my-md-3,
    .mb-md-3 {
        margin-bottom: 1rem !important;
    }
    
    .my-md-4,
    .mb-md-4 {
        margin-bottom: 1.5rem !important;
    }
    
    .my-md-5,
    .mb-md-5 {
        margin-bottom: 3rem !important;
    }
    
    .mx-md-auto,
    .mr-md-auto {
        margin-right: auto !important;
    }
    
    .mx-md-0,
    .mr-md-0 {
        margin-right: 0 !important;
    }
    
    .mx-md-1,
    .mr-md-1 {
        margin-right: 0.25rem !important;
    }
    
    .mx-md-2,
    .mr-md-2 {
        margin-right: 0.5rem !important;
    }
    
    .mx-md-3,
    .mr-md-3 {
        margin-right: 1rem !important;
    }
    
    .mx-md-4,
    .mr-md-4 {
        margin-right: 1.5rem !important;
    }
    
    .mx-md-5,
    .mr-md-5 {
        margin-right: 3rem !important;
    }
    
    .mx-md-auto,
    .ml-md-auto {
        margin-left: auto !important;
    }
    
    .mx-md-0,
    .ml-md-0 {
        margin-left: 0 !important;
    }
    
    .mx-md-1,
    .ml-md-1 {
        margin-left: 0.25rem !important;
    }
    
    .mx-md-2,
    .ml-md-2 {
        margin-left: 0.5rem !important;
    }
    
    .mx-md-3,
    .ml-md-3 {
        margin-left: 1rem !important;
    }
    
    .mx-md-4,
    .ml-md-4 {
        margin-left: 1.5rem !important;
    }
    
    .mx-md-5,
    .ml-md-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 992px) {
    .m-lg-auto {
        margin: auto !important;
    }
    
    .m-lg-0 {
        margin: 0 !important;
    }
    
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    
    .m-lg-3 {
        margin: 1rem !important;
    }
    
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    
    .m-lg-5 {
        margin: 3rem !important;
    }
    
    .my-lg-auto,
    .mt-lg-auto {
        margin-top: auto !important;
    }
    
    .my-lg-0,
    .mt-lg-0 {
        margin-top: 0 !important;
    }
    
    .my-lg-1,
    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }
    
    .my-lg-2,
    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }
    
    .my-lg-3,
    .mt-lg-3 {
        margin-top: 1rem !important;
    }
    
    .my-lg-4,
    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }
    
    .my-lg-5,
    .mt-lg-5 {
        margin-top: 3rem !important;
    }
    
    .my-lg-auto,
    .mb-lg-auto {
        margin-bottom: auto !important;
    }
    
    .my-lg-0,
    .mb-lg-0 {
        margin-bottom: 0 !important;
    }
    
    .my-lg-1,
    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .my-lg-2,
    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .my-lg-3,
    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }
    
    .my-lg-4,
    .mb-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    
    .my-lg-5,
    .mb-lg-5 {
        margin-bottom: 3rem !important;
    }
    
    .mx-lg-auto,
    .mr-lg-auto {
        margin-right: auto !important;
    }
    
    .mx-lg-0,
    .mr-lg-0 {
        margin-right: 0 !important;
    }
    
    .mx-lg-1,
    .mr-lg-1 {
        margin-right: 0.25rem !important;
    }
    
    .mx-lg-2,
    .mr-lg-2 {
        margin-right: 0.5rem !important;
    }
    
    .mx-lg-3,
    .mr-lg-3 {
        margin-right: 1rem !important;
    }
    
    .mx-lg-4,
    .mr-lg-4 {
        margin-right: 1.5rem !important;
    }
    
    .mx-lg-5,
    .mr-lg-5 {
        margin-right: 3rem !important;
    }
    
    .mx-lg-auto,
    .ml-lg-auto {
        margin-left: auto !important;
    }
    
    .mx-lg-0,
    .ml-lg-0 {
        margin-left: 0 !important;
    }
    
    .mx-lg-1,
    .ml-lg-1 {
        margin-left: 0.25rem !important;
    }
    
    .mx-lg-2,
    .ml-lg-2 {
        margin-left: 0.5rem !important;
    }
    
    .mx-lg-3,
    .ml-lg-3 {
        margin-left: 1rem !important;
    }
    
    .mx-lg-4,
    .ml-lg-4 {
        margin-left: 1.5rem !important;
    }
    
    .mx-lg-5,
    .ml-lg-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-auto {
        margin: auto !important;
    }
    
    .m-xl-0 {
        margin: 0 !important;
    }
    
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    
    .m-xl-3 {
        margin: 1rem !important;
    }
    
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    
    .m-xl-5 {
        margin: 3rem !important;
    }
    
    .my-xl-auto,
    .mt-xl-auto {
        margin-top: auto !important;
    }
    
    .my-xl-0,
    .mt-xl-0 {
        margin-top: 0 !important;
    }
    
    .my-xl-1,
    .mt-xl-1 {
        margin-top: 0.25rem !important;
    }
    
    .my-xl-2,
    .mt-xl-2 {
        margin-top: 0.5rem !important;
    }
    
    .my-xl-3,
    .mt-xl-3 {
        margin-top: 1rem !important;
    }
    
    .my-xl-4,
    .mt-xl-4 {
        margin-top: 1.5rem !important;
    }
    
    .my-xl-5,
    .mt-xl-5 {
        margin-top: 3rem !important;
    }
    
    .my-xl-auto,
    .mb-xl-auto {
        margin-bottom: auto !important;
    }
    
    .my-xl-0,
    .mb-xl-0 {
        margin-bottom: 0 !important;
    }
    
    .my-xl-1,
    .mb-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .my-xl-2,
    .mb-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .my-xl-3,
    .mb-xl-3 {
        margin-bottom: 1rem !important;
    }
    
    .my-xl-4,
    .mb-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    
    .my-xl-5,
    .mb-xl-5 {
        margin-bottom: 3rem !important;
    }
    
    .mx-xl-auto,
    .mr-xl-auto {
        margin-right: auto !important;
    }

    .mx-xl-0,
    .mr-xl-0 {
        margin-right: 0 !important;
    }
    
    .mx-xl-1,
    .mr-xl-1 {
        margin-right: 0.25rem !important;
    }
    
    .mx-xl-2,
    .mr-xl-2 {
        margin-right: 0.5rem !important;
    }
    
    .mx-xl-3,
    .mr-xl-3 {
        margin-right: 1rem !important;
    }
    
    .mx-xl-4,
    .mr-xl-4 {
        margin-right: 1.5rem !important;
    }
    
    .mx-xl-5,
    .mr-xl-5 {
        margin-right: 3rem !important;
    }
    
    .mx-xl-auto,
    .ml-xl-auto {
        margin-left: auto !important;
    }
    
    .mx-xl-0,
    .ml-xl-0 {
        margin-left: 0 !important;
    }
    
    .mx-xl-1,
    .ml-xl-1 {
        margin-left: 0.25rem !important;
    }
    
    .mx-xl-2,
    .ml-xl-2 {
        margin-left: 0.5rem !important;
    }
    
    .mx-xl-3,
    .ml-xl-3 {
        margin-left: 1rem !important;
    }
    
    .mx-xl-4,
    .ml-xl-4 {
        margin-left: 1.5rem !important;
    }
    
    .mx-xl-5,
    .ml-xl-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 1441px) {
    .m-exl-auto {
        margin: auto !important;
    }
    
    .m-exl-0 {
        margin: 0 !important;
    }
    
    .m-exl-1 {
        margin: 0.25rem !important;
    }
    
    .m-exl-2 {
        margin: 0.5rem !important;
    }
    
    .m-exl-3 {
        margin: 1rem !important;
    }
    
    .m-exl-4 {
        margin: 1.5rem !important;
    }
    
    .m-exl-5 {
        margin: 3rem !important;
    }
    
    .my-exl-auto,
    .mt-exl-auto {
        margin-top: auto !important;
    }
    
    .my-exl-0,
    .mt-exl-0 {
        margin-top: 0 !important;
    }
    
    .my-exl-1,
    .mt-exl-1 {
        margin-top: 0.25rem !important;
    }
    
    .my-exl-2,
    .mt-exl-2 {
        margin-top: 0.5rem !important;
    }
    
    .my-exl-3,
    .mt-exl-3 {
        margin-top: 1rem !important;
    }
    
    .my-exl-4,
    .mt-exl-4 {
        margin-top: 1.5rem !important;
    }
    
    .my-exl-5,
    .mt-exl-5 {
        margin-top: 3rem !important;
    }
    
    .my-exl-auto,
    .mb-exl-auto {
        margin-bottom: auto !important;
    }
    
    .my-exl-0,
    .mb-exl-0 {
        margin-bottom: 0 !important;
    }
    
    .my-exl-1,
    .mb-exl-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .my-exl-2,
    .mb-exl-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .my-exl-3,
    .mb-exl-3 {
        margin-bottom: 1rem !important;
    }
    
    .my-exl-4,
    .mb-exl-4 {
        margin-bottom: 1.5rem !important;
    }
    
    .my-exl-5,
    .mb-exl-5 {
        margin-bottom: 3rem !important;
    }
    
    .mx-exl-auto,
    .mr-exl-auto {
        margin-right: auto !important;
    }
    
    .mx-exl-0,
    .mr-exl-0 {
        margin-right: 0 !important;
    }
    
    .mx-exl-1,
    .mr-exl-1 {
        margin-right: 0.25rem !important;
    }
    
    .mx-exl-2,
    .mr-exl-2 {
        margin-right: 0.5rem !important;
    }
    
    .mx-exl-3,
    .mr-exl-3 {
        margin-right: 1rem !important;
    }
    
    .mx-exl-4,
    .mr-exl-4 {
        margin-right: 1.5rem !important;
    }
    
    .mx-exl-5,
    .mr-exl-5 {
        margin-right: 3rem !important;
    }
    
    .mx-exl-auto,
    .ml-exl-auto {
        margin-left: auto !important;
    }
    
    .mx-exl-0,
    .ml-exl-0 {
        margin-left: 0 !important;
    }
    
    .mx-exl-1,
    .ml-exl-1 {
        margin-left: 0.25rem !important;
    }
    
    .mx-exl-2,
    .ml-exl-2 {
        margin-left: 0.5rem !important;
    }
    
    .mx-exl-3,
    .ml-exl-3 {
        margin-left: 1rem !important;
    }
    
    .mx-exl-4,
    .ml-exl-4 {
        margin-left: 1.5rem !important;
    }
    
    .mx-exl-5,
    .ml-exl-5 {
        margin-left: 3rem !important;
    }
}
/* MARGIN CLASSES END */