@media (min-width: 321px) and (max-width: 768px) {
    .masonry-card {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media all and (max-width: 330px) {
    .topnav ul li {
        display: $display-block;
        width: 94%;
    }
}

@media all and (max-width: 700px) {
    .minh-400 {
        min-height: 100px !important;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .masonry-card {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 320px) {
    .masonry-card {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}

@media (max-width: 360px) {
    .main-header .menu-toggle {
        margin: $margin-padding-0;
    }

    .layout-sidebar-large .main-header .menu-toggle {
        margin: $margin-padding-0;
    }
}

@media (max-width: 480px) {
    .sp-color {
        right: 40%;
    }

    .sp-hue {
        left: 63%;
    }

    .sp-fill {
        @include padding-top(60%);
    }

    .login-inner-wrapper {
        @include padding(20px, 20px, 20px, 20px);
        width: 280px;
    }

    .login-header figure {
        width: 240px;
    }

    .signup-input .validate-input .form-control-lg {
        height: 50px;
    }

    .signup-input .btn {
        @include padding(8px, 20px, 8px, 20px);
    }
}

@media (max-width: 1300px) {
    .text-40 {
        font-size: 28px;
    }

    .text-28 {
        font-size: 22px !important;
    }
}

@media (max-width: 530px) {
    .mobile-hide {
        display: $display-none !important;
    }
}

@media (min-width: 576px) {
    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: $margin-padding-0;
    }

    .offset-sm-1 {
        @include margin-left(8.33333%);
    }

    .offset-sm-2 {
        @include margin-left(16.66667%);
    }

    .offset-sm-3 {
        @include margin-left(25%);
    }

    .offset-sm-4 {
        @include margin-left(33.33333%);
    }

    .offset-sm-5 {
        @include margin-left(41.66667%);
    }

    .offset-sm-6 {
        @include margin-left(50%);
    }

    .offset-sm-7 {
        @include margin-left(58.33333%);
    }

    .offset-sm-8 {
        @include margin-left(66.66667%);
    }

    .offset-sm-9 {
        @include margin-left(75%);
    }

    .offset-sm-10 {
        @include margin-left(83.33333%);
    }

    .offset-sm-11 {
        @include margin-left(91.66667%);
    }

    .d-sm-none {
        display: $display-none !important;
    }

    .d-sm-inline {
        display: $display-inline !important;
    }

    .d-sm-inline-block {
        display: $display-inline-block !important;
    }

    .d-sm-block {
        display: $display-block !important;
    }

    .d-sm-table {
        display: $diplay-table !important;
    }

    .d-sm-table-row {
        display: $display-table-row !important;
    }

    .d-sm-table-cell {
        display: $diplay-table-cell !important;
    }

    .d-sm-flex {
        display: $display-flex !important;
    }

    .d-sm-inline-flex {
        display: $display-inline-flex !important;
    }

    .flex-sm-row {
        flex-direction: $flex-direction-row !important;
    }

    .flex-sm-column {
        flex-direction: $flex-direction-column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: $flex-direction-row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: $flex-direction-column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: $space-between !important;
    }

    .justify-content-sm-around {
        justify-content: $space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: $center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }

    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }

    .text-sm-left {
        text-align: $start !important;
    }

    .text-sm-right {
        text-align: $end !important;
    }

    .text-sm-center {
        text-align: $center !important;
    }
}

@media (max-width: 575px) {
    .table-responsive-sm {
        display: $display-block;
        width: $height-width-100;
        overflow-x: $overflow-auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }

    .main-header {
        height: 70px;
        @include padding(0px, 1.5rem, 0px, 1.5rem);
    }

    .main-header .logo {
        width: 60px;
    }

    .main-header .header-part-right .user {
        margin-right: $margin-padding-0;
        padding-right: $margin-padding-0;
    }

    .notification-dropdown {
        left: -180px !important;
    }

    .main-content-wrap {
        @include padding(1.5rem, 1.5rem, 1.5rem, 1.5rem);
    }

    .main-content-wrap.sidenav-open {
        width: $height-width-100;
    }

    .main-content-wrap {
        @include margin-top(70px);
    }

    .sidebar-left {
        left: calc(-90px - 20px);
    }

    .sidebar-left .navigation-left {
        width: 90px;
    }

    .sidebar-left .navigation-left .nav-item.active .triangle {
        border-width: 0 0 24px 24px;
    }

    .sidebar-left .navigation-left .nav-item .nav-item-hold {
        @include padding(16px, 0px, 16px, 0px);
    }

    .sidebar-left-secondary {
        left: calc(-190px - 20px);
        width: 190px;
    }

    .sidebar-left-secondary.open {
        left: 90px;
    }

    .sidebar-overlay {
        height: calc(100vh - 70px);
    }

    .layout-sidebar-large .main-header {
        height: 70px;
        @include padding(0px, 1.5rem, 0px, 1.5rem);
    }

    .layout-sidebar-large .main-header .logo {
        width: 60px;
    }

    .layout-sidebar-large .main-header .search-bar {
        display: $display-none;
    }

    .layout-sidebar-large .main-header .menu-toggle {
        width: 60px;
    }

    .layout-sidebar-large .main-header .header-part-right .user {
        margin-right: $margin-padding-0;
        padding-right: $margin-padding-0;
    }

    .layout-sidebar-large .notification-dropdown {
        left: -0px !important;
    }

    .main-content-wrap {
        @include padding(1.5rem, 1.5rem, 1.5rem, 1.5rem);
    }

    .main-content-wrap.sidenav-open {
        width: $height-width-100;
    }

    .main-content-wrap {
        @include margin-top(70px);
    }

    .sidebar-left {
        left: calc(-90px - 20px);
    }

    .sidebar-left .navigation-left {
        width: 90px;
    }

    .sidebar-left .navigation-left .nav-item.active .triangle {
        border-width: 0 0 24px 24px;
    }

    .sidebar-left .navigation-left .nav-item .nav-item-hold {
        @include padding(16px, 0px, 16px, 0px);
    }

    .sidebar-left-secondary {
        left: calc(-190px - 20px);
        width: 190px;
    }

    .sidebar-left-secondary.open {
        left: 90px;
    }

    .sidebar-overlay {
        height: calc(100vh - 70px);
    }

    .main-content-wrap {
        @include padding(1.5rem, 1.5rem, 1.5rem, 1.5rem);
    }

    .main-content-wrap.sidenav-open {
        width: $height-width-100;
    }

    .main-content-wrap {
        @include margin-top(70px);
    }

    .card-ecommerce-3 .card-img-left {
        width: $height-width-100;
    }

    .breadcrumb {
        flex-direction: $flex-direction-column;
        align-items: flex-start;
    }

    .breadcrumb ul li:first-child {
        @include padding-left(0px);
    }

    .list-horizontal .list-item .item-title {
        max-width: 150px;
    }

    .filter-find>.filter-category-items>span {
        white-space: normal;
    }
}

@media (max-width: 615px) {
    .login-inner-wrapper {
        width: calc(100vw - 60px);
    }

    .login-header figure {
        width: calc(100vw - 138px);
        height: auto;
    }

    .login-header figure img {
        position: $position-relative;
    }
}

@media (min-width: 768px) {
    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: $margin-padding-0;
    }

    .offset-md-1 {
        @include margin-left(8.33333%);
    }

    .offset-md-2 {
        @include margin-left(16.66667%);
    }

    .offset-md-3 {
        @include margin-left(25%);
    }

    .offset-md-4 {
        @include margin-left(33.33333%);
    }

    .offset-md-5 {
        @include margin-left(41.66667%);
    }

    .offset-md-6 {
        @include margin-left(50%);
    }

    .offset-md-7 {
        @include margin-left(58.33333%);
    }

    .offset-md-8 {
        @include margin-left(66.66667%);
    }

    .offset-md-9 {
        @include margin-left(75%);
    }

    .offset-md-10 {
        @include margin-left(83.33333%);
    }

    .offset-md-11 {
        @include margin-left(91.66667%);
    }

    .d-md-none {
        display: $display-none !important;
    }

    .d-md-inline {
        display: $display-inline !important;
    }

    .d-md-inline-block {
        display: $display-inline-block !important;
    }

    .d-md-block {
        display: $display-block !important;
    }

    .d-md-table {
        display: $diplay-table !important;
    }

    .d-md-table-row {
        display: $display-table-row !important;
    }

    .d-md-table-cell {
        display: $diplay-table-cell !important;
    }

    .d-md-flex {
        display: $display-flex !important;
    }

    .d-md-inline-flex {
        display: $display-inline-flex !important;
    }

    .flex-md-row {
        flex-direction: $flex-direction-row !important;
    }

    .flex-md-column {
        flex-direction: $flex-direction-column !important;
    }

    .flex-md-row-reverse {
        flex-direction: $flex-direction-row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: $flex-direction-column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: $space-between !important;
    }

    .justify-content-md-around {
        justify-content: $space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: $center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }

    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }

    .text-md-left {
        text-align: $start !important;
    }

    .text-md-right {
        text-align: $end !important;
    }

    .text-md-center {
        text-align: $center !important;
    }

    div.right-sidebar.sm-sidebar {
        max-width: 450px !important;
        width: 450px !important;
    }

    .sidebar-modal .card-sidebar .card-sidebar-header .left-sidebar-toggle {
        display: $display-none;
    }

    .sidebar-modal .card-sidebar .card-sidebar-header .tasklist-close {
        display: $display-none;
    }

    .masonry-card.masonry-qa-card {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }

    .layout-sidebar-compact .main-content-wrap {
        width: calc(100% - 60px);
    }

    .layout-sidebar-compact .sidebar-left {
        left: $left;
    }

    .layout-sidebar-compact .sidebar-left .navigation-left {
        width: 60px;
    }

    .layout-sidebar-compact .sidebar-left:hover .navigation-left {
        width: 250px;
    }

    .layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-text {
        opacity: 0;
    }

    .layout-sidebar-compact .sidebar-left:hover .navigation-left .nav-item .nav-item-hold .nav-text {
        opacity: 1;
    }

    .layout-sidebar-compact .sidebar-left .navigation-left .nav-item.sidebar-control a.sidebar-menu {
        opacity: 0;
    }

    .layout-sidebar-compact .sidebar-left:hover .navigation-left .nav-item.sidebar-control a.sidebar-menu {
        opacity: 1;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
    }

    .nav-item-toggle .accordion-button span:after {
        opacity: 0;
    }

    .layout-sidebar-compact .sidebar-left:hover .navigation-left .nav-item .nav-item-hold.nav-item-toggle .accordion-button span:after {
        opacity: 1;
    }

    .layout-sidebar-compact.sidenav-open-sticky .main-content-wrap {
        width: calc(100% - 250px) !important;
    }

    .layout-sidebar-compact.sidenav-open-sticky .sidebar-left .navigation-left {
        width: 250px;
    }

    .navigation-menu-sub.collapse.show {
        opacity: 0;
        display: $display-block;
    }

    .layout-sidebar-compact .sidebar-left:hover .navigation-left .navigation-menu-sub.collapse.show,
    .layout-sidebar-compact.sidenav-open-sticky .sidebar-left .navigation-left .navigation-menu-sub.collapse.show {
        opacity: 1;
        display: $display-block;
    }

    .dndflow {
        flex-direction: $flex-direction-row;
    }
}

@media (max-width: 767px) {
    .table-responsive-md {
        display: $display-block;
        width: $height-width-100;
        overflow-x: $overflow-auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }

    .main-header .search-bar {
        display: $display-none;
    }

    .main-header .menu-toggle {
        width: 24px;
        @include margin-right(36px);
    }

    .layout-sidebar-compact.sidenav-open .main-content-wrap {
        width: $height-width-100;
    }

    .layout-sidebar-compact .sidebar-left-secondary .sidebar-close {
        display: $display-block;
    }

    .layout-sidebar-large .main-header .search-bar {
        display: $display-none;
    }

    .layout-sidebar-large .main-header .menu-toggle {
        width: 24px;
        @include margin-right(36px);
    }

    .sidebar-container .sidebar {
        background: var(--white);
    }

    .sidebar-container .sidebar .sidebar-close {
        display: $display-block;
    }

    .search-ui {
        @include padding(1rem, 1rem, 1rem, 1rem);
    }

    .main-header {
        height: 70px;
        @include padding(0px, 1.5rem, 0px, 1.5rem);
    }

    .mail-item {
        @include padding(1rem, 0.5rem, 1rem, 0.5rem !important);
    }

    .inbox-secondary-sidebar {
        width: 280px !important;
    }

    .user-profile .header-cover {
        height: 200px;
    }

    .timeline .timeline-item {
        width: $height-width-100;
        @include padding(4rem, 0px, 3rem, 0px !important);
    }

    .timeline .timeline-item:nth-child(odd) {
        @include margin-top(1rem);
    }

    .timeline .timeline-item .timeline-badge {
        left: $left !important;
        right: $right !important;
        top: -16px;
        margin: $margin-auto;
    }

    .timeline .timeline-group {
        @include padding(0px, 0px, 3rem, 0px);
    }

    .auth-layout-wrap .auth-content {
        @include padding(15px, 15px, 15px, 15px);
    }

    .auth-right {
        @include padding(80px, 15px, 80px, 15px);
    }

    .main-header .navbar-nav {
        flex-direction: $flex-direction-row;
    }

    .main-header .navbar-nav .nav-item {
        @include margin-right(8px);
    }

    .main-header .topbar .header-nav {
        display: $display-block;
    }

    .brand {
        display: $display-flex;
        justify-content: space-between;
        width: $height-width-100;
    }

    .dashboard {
        height: 250px;
    }

    .features .features-wrap {
        display: $display-flex;
        flex-direction: $flex-direction-row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .features .features-wrap .feature-card {
        @include padding(10px, 10px, 10px, 10px);
        @include margin(5px, 5px, 5px, 5px);
    }

    .features .features-wrap .feature-card .card-title {
        margin: $margin-padding-0;
    }

    .features .features-wrap .feature-card .card-title h6 {
        margin: $margin-padding-0;
    }

    .features .features-wrap .feature-card .card-icon {
        display: $display-none;
    }

    .framework .item-photo {
        @include margin-bottom(30px);
    }

    .component .component-list {
        margin: 0 $margin-auto;
    }

    .component .component-list ul {
        text-align: center;
        padding: $margin-padding-0;
    }

    .component .component-list ul li {
        @include margin-left(30px);
    }

    .component .component-list .comoponent-list-heading {
        @include margin-left(3px !important);
    }

    .footer .footer-item .social-media {
        @include margin-top(18px);
    }

    .footer .footer-item .social-media ul {
        padding: $margin-padding-0;
    }

    .footer .footer-item .social-media ul li {
        @include margin-right(20px);
        margin-left: $margin-padding-0;
    }

    .footer .footer-item .selling-btn {
        @include margin-top(10px);
    }

    .footer .footer-item .btn-arrow {
        @include margin-top(10px);
    }

    .sidebar-modal .right-sidebar-card-modal {
        flex: 0 0 100%;
        max-width: $height-width-100;
    }

    .sidebar-modal .right-sidebar-list {
        position: $position-absolute;
        -webkit-transform: translateX(-110%);
        -ms-transform: translateX(-110%);
        transform: translateX(-110%);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        z-index: 9;
    }

    .sidebar-modal .right-sidebar-list.active-left-sidebar {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    .sidebar-modal .right-sidebar-list .card-sidebar {
        background-color: var(--white);
        box-shadow: 5px 0px 10px -10px #000;
    }

    .card-sidebar .card-sidebar-header h4 {
        font-size: 16px;
    }

    .card-sidebar.card-id .card-sidebar-header .task-id {
        border-radius: 0 0 5px 5px;
        @include padding(2px, 5px, 2px, 5px);
        bottom: 8px;
    }
}

@media (min-width: 992px) {
    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: $margin-padding-0;
    }

    .offset-lg-1 {
        @include margin-left(8.33333%);
    }

    .offset-lg-2 {
        @include margin-left(16.66667%);
    }

    .offset-lg-3 {
        @include margin-left(25%);
    }

    .offset-lg-4 {
        @include margin-left(33.33333%);
    }

    .offset-lg-5 {
        @include margin-left(41.66667%);
    }

    .offset-lg-6 {
        @include margin-left(50%);
    }

    .offset-lg-7 {
        @include margin-left(58.33333%);
    }

    .offset-lg-8 {
        @include margin-left(66.66667%);
    }

    .offset-lg-9 {
        @include margin-left(75%);
    }

    .offset-lg-10 {
        @include margin-left(83.33333%);
    }

    .offset-lg-11 {
        @include margin-left(91.66667%);
    }

    .d-lg-none {
        display: $display-none !important;
    }

    .d-lg-inline {
        display: $display-inline !important;
    }

    .d-lg-inline-block {
        display: $display-inline-block !important;
    }

    .d-lg-block {
        display: $display-block !important;
    }

    .d-lg-table {
        display: $diplay-table !important;
    }

    .d-lg-table-row {
        display: $display-table-row !important;
    }

    .d-lg-table-cell {
        display: $diplay-table-cell !important;
    }

    .d-lg-flex {
        display: $display-flex !important;
    }

    .d-lg-inline-flex {
        display: $display-inline-flex !important;
    }

    .flex-lg-row {
        flex-direction: $flex-direction-row !important;
    }

    .flex-lg-column {
        flex-direction: $flex-direction-column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: $flex-direction-row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: $flex-direction-column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: $space-between !important;
    }

    .justify-content-lg-around {
        justify-content: $space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: $center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }

    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }

    .text-lg-left {
        text-align: $start !important;
    }

    .text-lg-right {
        text-align: $end !important;
    }

    .text-lg-center {
        text-align: $center !important;
    }

    div.right-sidebar.md-sidebar {
        max-width: 930px !important;
        width: 930px !important;
    }

    .pdd-mobile {
        display: $display-none !important;
    }

    .xl-modal {
        max-width: 900px;
    }

    .rs-col-md-12 {
        width: $height-width-100;
    }

    .rs-col-md-12 {
        width: $height-width-100;
    }

    .notification-sidebar {
        max-width: 600px !important;
        width: 600px !important;
    }
}

@media (max-width: 991px) {
    .table-responsive-lg {
        display: $display-block;
        width: $height-width-100;
        overflow-x: $overflow-auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .candidate-right-data-right-sec {
        overflow-y: $overflow-auto;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }

    .layout-sidebar-large .main-header .search-bar {
        width: 180px;
    }

    .layout-sidebar-large .main-header .menu-toggle {
        width: 24px;
        @include margin-right(36px);
    }

    .list-horizontal .list-item .list-thumb img {
        height: 100%;
        width: 100px;
    }

    .list-horizontal .list-item .item-title {
        max-width: 200px;
    }

    .project-table th:first-child,
    .project-table td:first-child,
    .project-table th:nth-child(2),
    .project-table td:nth-child(2) {
        position: $position-relative;
        left: auto !important;
    }

    div.right-sidebar {
        width: $height-width-100 !important;
        top: $top !important;
    }

    .project-detail-dashboard.pdd-mobile {
        width: $height-width-100;
        padding: $margin-padding-0;
        position: $position-relative;
        top: -10px;
    }

    .main-content .project-dashboard-tabs {
        @include margin-top(-5px !important);
    }

    .card-header-right {
        opacity: 1;
    }

    .right-sidebar.loan-sidebar-modal,
    .right-sidebar.sidebar-modal {
        width: calc(100% - 20px) !important;
        height: calc(100% - 20px) !important;
        top: 10px !important;
    }

    .right-sidebar.loan-sidebar-modal.active-right-sidebar,
    .right-sidebar.sidebar-modal.active-right-sidebar {
        right: 10px !important;
    }

    .sidebar-modal .right-sidebar-list {
        flex: 0 0 300px;
        max-width: 300px;
    }

    .sidebar-modal .right-sidebar-card-modal {
        flex: 0 0 calc(100% - 300px);
        max-width: calc(100% - 300px);
    }

    .sidebar-modal .card-sidebar.card-nofooter .card-sidebar-body {
        height: calc(100vh - 63px);
    }

    .sidebar-modal .card-sidebar.card-id .card-sidebar-body {
        height: calc(100vh - 67px);
    }
}

@media screen and (max-width: 1025px) {
    .nutral-icon {
        left: -44px !important;
    }
}

@media (max-width: 1024px) {
    .main-header .search-bar {
        width: 180px;
        display: $display-none;
    }

    .main-header .header-part-right .user {
        @include margin-right(1rem);
    }
}

@media (min-width: 1024px) {
    .auth-layout-wrap .auth-content {
        min-width: 660px;
    }
}

@media (max-width: 1150px) {
    .sidebar-switch-open {
        left: 0px !important;
    }

    .app-admin-wrap.sidebar-full .sidebar-panel {
        left: -240px;
    }

    .header-toggle .mobile-menu-icon {
        display: $display-block !important;
    }

    .switch-overlay {
        height: calc(100vh - 70px);
        display: $display-block;
        position: $position-fixed;
        width: calc(100% - 240px);
        height: calc(100vh - 5px);
        bottom: $bottom;
        right: $right;
        background: rgba(0, 0, 0, 0);
        z-index: 20000;
        cursor: w-resize;
    }

    .app-admin-wrap.sidebar-full .main-content-wrap {
        width: calc(100% - 0px);
    }

    .sidebar-compact-switch {
        display: $display-none;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: $margin-padding-0;
    }

    .offset-xl-1 {
        @include margin-left(8.33333%);
    }

    .offset-xl-2 {
        @include margin-left(16.66667%);
    }

    .offset-xl-3 {
        @include margin-left(25%);
    }

    .offset-xl-4 {
        @include margin-left(33.33333%);
    }

    .offset-xl-5 {
        @include margin-left(41.66667%);
    }

    .offset-xl-6 {
        @include margin-left(50%);
    }

    .offset-xl-7 {
        @include margin-left(58.33333%);
    }

    .offset-xl-8 {
        @include margin-left(66.66667%);
    }

    .offset-xl-9 {
        @include margin-left(75%);
    }

    .offset-xl-10 {
        @include margin-left(83.33333%);
    }

    .offset-xl-11 {
        @include margin-left(91.66667%);
    }

    .d-xl-none {
        display: $display-none !important;
    }

    .d-xl-inline {
        display: $display-inline !important;
    }

    .d-xl-inline-block {
        display: $display-inline-block !important;
    }

    .d-xl-block {
        display: $display-block !important;
    }

    .d-xl-table {
        display: $diplay-table !important;
    }

    .d-xl-table-row {
        display: $display-table-row !important;
    }

    .d-xl-table-cell {
        display: $diplay-table-cell !important;
    }

    .d-xl-flex {
        display: $display-flex !important;
    }

    .d-xl-inline-flex {
        display: $display-inline-flex !important;
    }

    .flex-xl-row {
        flex-direction: $flex-direction-row !important;
    }

    .flex-xl-column {
        flex-direction: $flex-direction-column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: $flex-direction-row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: $flex-direction-column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: $space-between !important;
    }

    .justify-content-xl-around {
        justify-content: $space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: $center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }

    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }

    .text-xl-left {
        text-align: $start !important;
    }

    .text-xl-right {
        text-align: $end !important;
    }

    .text-xl-center {
        text-align: $center !important;
    }

    .masonry-card {
        -moz-column-count: 4;
        -webkit-column-count: 4;
        column-count: 4;
    }

    .masonry-card.masonry-qa-card {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }

    .xl-modal {
        max-width: 1100px;
    }

    .inline-filter-dropdown {
        position: $position-relative;
        top: 22px;
    }
}

@media (max-width: 1199px) {
    .table-responsive-xl {
        display: $display-block;
        width: $height-width-100;
        overflow-x: $overflow-auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1298px) {
    .nav-tabs.main-tabss .nav-link.active:after {
        bottom: -7px;
    }

    .nav-tabs.main-tabss .nav-link.active:before {
        bottom: -4px;
        display: $display-none;
    }

    .followup-detail-activity-icon {
        left: -14px !important;
    }

    .followup-detail-activity-title {
        font-size: 18px !important;
        @include margin-left(20px !important);
    }

    .followup-details-icons {
        padding: $margin-padding-0 !important;
        height: 50px !important;
    }

    .application-activity-btn {
        width: 30px !important;
        height: 30px !important;
    }

    .followupdetail-info-card {
        margin-left: $margin-padding-0 !important;
    }

    .followup-details-info-icon {
        left: -58px !important;
    }

    .followupdetails-activity-btn {
        font-size: 12px;
        width: 100px;
        height: 35px;
    }
}

@media (min-width:1441px) {
    .col-exl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: $height-width-100
    }

    .row-cols-exl-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: $height-width-100
    }

    .row-cols-exl-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-exl-3>* {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .row-cols-exl-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-exl-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-exl-6>* {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-exl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: $height-width-100
    }

    .col-exl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-exl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-exl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-exl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-exl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-exl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-exl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-exl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-exl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-exl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-exl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-exl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: $height-width-100
    }

    .order-exl-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-exl-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-exl-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-exl-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-exl-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-exl-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-exl-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-exl-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-exl-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-exl-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-exl-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-exl-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-exl-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-exl-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-exl-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-exl-0 {
        margin-left: $margin-padding-0;
    }

    .offset-exl-1 {
        @include margin-left(8.333333%);
    }

    .offset-exl-2 {
        @include margin-left(16.666667%);
    }

    .offset-exl-3 {
        @include margin-left(25%);
    }

    .offset-exl-4 {
        @include margin-left(33.333333%);
    }

    .offset-exl-5 {
        @include margin-left(41.666667%);
    }

    .offset-exl-6 {
        @include margin-left(50%);
    }

    .offset-exl-7 {
        @include margin-left(58.333333%);
    }

    .offset-exl-8 {
        @include margin-left(66.666667%);
    }

    .offset-exl-9 {
        @include margin-left(75%);
    }

    .offset-exl-10 {
        @include margin-left(83.333333%);
    }

    .offset-exl-11 {
        @include margin-left(91.666667%);
    }

    .mt-exl-0 {
        margin-top: $top !important;
    }

    .mb-exl-0 {
        margin-bottom: $margin-padding-0 !important;
    }

    div.right-sidebar.lg-sidebar {
        width: 1400px !important;
    }
}

@media (max-width:1800px) {
    .wallet-img {
        width: 150px;
    }

    .text-45 {
        font-size: 33px !important;
    }
}

@media (max-width:1650px) {
    .wallet-img {
        width: 140px;
    }
}

@media (max-width: 1440px) {
    .addon-list {
        p {
            padding-left: 12px;
        }
    }
}

@media (max-width: 1400px) {
    .release-note-description {
        max-width: 500px;
    }

    .app-release-sidebar {
        width: 300px;
    }
    .app-release-body {
        width: calc(100% - 348px);
    }
}
@media (max-width: 1100px){
    .text-25 {
        font-size: 21px;
    }
    .release-note-description {
        max-width: 300px;
    }
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (max-width: 1200px) {
    .text-16 {
        font-size: 14px !important;
    }

    .text-18 {
        font-size: 16px !important;
    }

}

//  | Deep Dungarani  ~ 2025-01-20 ~ 10:38 AM
@media (max-width: 1366px) {
    h4,
    .h4 {
        font-size: 15px;
    }
}

@media print {
    .d-print-none {
        display: $display-none !important;
    }

    .d-print-inline {
        display: $display-inline !important;
    }

    .d-print-inline-block {
        display: $display-inline-block !important;
    }

    .d-print-block {
        display: $display-block !important;
    }

    .d-print-table {
        display: $diplay-table !important;
    }

    .d-print-table-row {
        display: $display-table-row !important;
    }

    .d-print-table-cell {
        display: $diplay-table-cell !important;
    }

    .d-print-flex {
        display: $display-flex !important;
    }

    .d-print-inline-flex {
        display: $display-inline-flex !important;
    }
}