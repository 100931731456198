.light-colors {
    --dark: #111727;
    --dark-transparent: #11172720;
    --dark-900: #1d1a49;
    --dark-900-transparent: #1d1a4920;
    --dark-800: #36345d;
    --dark-800-transparent: #36345d20;
    --dark-700: #4e4c72;
    --dark-700-transparent: #4e4c7220;
    --dark-600: #686786;
    --dark-600-transparent: #68678620;
    --dark-500: #81809a;
    --dark-500-transparent: #81809a20;
    --dark-400: #9b99ae;
    --dark-400-transparent: #9b99ae20;
    --dark-300: #b3b2c2;
    --dark-300-transparent: #b3b2c220;
    --dark-200: #cdccd7;
    --dark-200-transparent: #cdccd720;
    --dark-100: #e5e5ea;
    --dark-100-transparent: #e5e5ea20;
    --gray: #222222;
    --gray-transparent: #22222220;
    --gray-900: #3b3b3b;
    --gray-900-transparent: #3b3b3b20;
    --gray-800: #515151;
    --gray-800-transparent: #51515120;
    --gray-700: #626262;
    --gray-700-transparent: #62626220;
    --gray-600: #7e7e7e;
    --gray-600-transparent: #7e7e7e20;
    --gray-500: #9e9e9e;
    --gray-500-transparent: #9e9e9e20;
    --gray-400: #b1b1b1;
    --gray-400-transparent: #b1b1b120;
    --gray-300: #cfcfcf;
    --gray-300-transparent: #cfcfcf20;
    --gray-200: #e1e1e1;
    --gray-200-transparent: #e1e1e120;
    --gray-100: #f7f7f7;
    --gray-100-transparent: #f7f7f720;
    --primary: #0066ff;
    --primary-dark: #004CAF;
    --primary-light: #75A8FF;
    --primary-light-300: #75839694;
    --primary-light-100: #f8fafe;
    --primary-transparent: #0066ff20;
    --secondary: #6C757D;
    --secondary-dark: #343A40;
    --secondary-light: #ADB5BD;
    --secondary-transparent: #6C757D20;
    --success: #1DAB45;
    --success-dark: #1DAB45;
    --success-light: #1DAB45;
    --success-transparent: #1DAB4520;
    --success-light-100: #e8f6f2;
    --warning-light-100: #fff5e4;
    --purple-light-100: #f5f0fe;
    --danger: #D30808;
    --danger-dark: #B50707;
    --danger-light: #F60F0F;
    --danger-transparent: #D3080820;
    --warning: #FFB223;
    --warning-dark: #F9A200;
    --warning-light: #FFBD43;
    --warning-transparent: #FFB22320;
    --info: #0092C2;
    --info-dark: #0079A0;
    --info-light: #03ACD5;
    --info-transparent: #0092C220;
    --common-white: #FFFFFF;     // Deep Dungarani
    --white: #FFFFFF;
    --white-color: #FFFFFF;
    --black: #000000;
    --high: #7D0000;
    --medium: #B64600;
    --low: #956D00;
    --none: #007E33;
    --light: #e8edfa;
    --main-content-bg-color: #F9F9F9;
    --input-border: #B0B0B061;
    --input-border-hover: #c4cfdc;
    --dropdown-hover: #f1f2f9;
    --dropdown-active: #f8f9ff;
    --input-label: #636363;
    --icon-color: #b0c5d7;
    --calendar-light: #fafafa;
    --transparent: #00000000;
    --placeholder-color: #585858;
    --input-text-color: #555555;
    --card-shadow: #cdd4e7;
    --dropdown-menu-shadow: 0px 0px 10px 1px rgb(0 0 0 / 8%);
    --green-text: #007E33;
    --green-bg: #F0FFEB;
    --red-text: #C20000;
    --red-bg: #FFEBEB;
    --orange-text: #B64600;
    --orange-bg: #FFF5EB;
    --yellow-text: #956D00;
    --yellow-bg: #FFFBDF;
    --btn-primary: #3366ff;
    --btn-primary-dark: #2755e1;
    --btn-primary-transparent: #3366ff1a;
    --btn-secondary: #6d9cbd;
    --btn-secondary-dark: #5486a9;
    --btn-secondary-transparent: #6d9cbd1a;
    --btn-success: #157347;
    --btn-success-dark: #1b4c43;
    --btn-success-transparent: #1573471a;
    --btn-info: #717171;
    --btn-info-dark: #565e64;
    --btn-info-transparent: #7171711a;
    --btn-warning: #e2c636;
    --btn-warning-dark: #ffc310;
    --btn-warning-transparent: #e2c6361a;
    --btn-danger: #d22d3d;
    --btn-danger-dark: #ab1616;
    --btn-danger-transparent: #d22d3d1a;
    --calendar-schedule: #f1f2fe;
    --calendar-reschedule: #eff6f4;
    --calendar-followup: #f5cdd3;
    --calendar-agreement: #fcf5de;
    --calendar-canceled: #9e9e9e20;
    --brown-icon: #ad6359;
    --yellow-icon: #e4c556;
    --green-icon: #1ab595;
    --primary-icon: #58a4fd;
    --brown-bg-icon: #faf6f5;
    --yellow-bg-icon: #fbf6e6;
    --green-bg-icon: #edf9f7;
    --primary-bg-icon: rgba(115, 168, 252, 0.08);
    --purple: #6046C8;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --referral-descri: #434343;
    --required-border: #d4e3ff;
    --required-bg: #ECF0FA;
    --wallet-th: #525252;
    --disabled-bgcolor: #DDDDDD;
    --attendance-bg: #FFFFFF;
    --myprofile-header: #E1EAFC;
    --package-btn-border: rgb(236, 236, 236);
    --input-placeholder-color: #00000069;
    --tabs-arrow: #051B44;
    --active-plan-shadow: #CBCFE8;
    --userthree-counter: #1EB966;
    --release-submenu-item: #585858;
    --release-note-dis: #3D3D3D;
    --release-arrow: #fff;
    --release-sidebar-border: #F1F1F1;
    --package-card: #FAFAFA;
    --active-plan-date: #FAFAFA;
    --tabs-arrow-border: #D8DAE5;
    --package-paid-bg: #EFF4FD;
    --total-licence-bg: #dfe8f6;
    --checkout-bg: #fef6e4;
    --attendance-border: #EDEDED;
    --home-main-title: #4C4E64DE;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --flow-content-border: #DADADA;
    --header-active-dark: #5956E3;
    --employee-highlight: #fff2f2;
    --leave-type: #6d7fff;

}

.dark-color {
    --dark: #D9DADF;
    --dark-transparent: #D9DADF20;
    --dark-900: #e5e5ea;
    --dark-900-transparent: #e5e5ea20;
    --dark-800: #cdccd7;
    --dark-800-transparent: #cdccd720;
    --dark-700: #b3b2c2;
    --dark-700-transparent: #b3b2c220;
    --dark-600: #9b99ae;
    --dark-600-transparent: #9b99ae20;
    --dark-500: #81809a;
    --dark-500-transparent: #81809a20;
    --dark-400: #686786;
    --dark-400-transparent: #68678620;
    --dark-300: #4e4c72;
    --dark-300-transparent: #4e4c7220;
    --dark-200: #36345d;
    --dark-200-transparent: #36345d20;
    --dark-100: #1d1a49;
    --dark-100-transparent: #1d1a4920;
    --gray: #F7F7FA;
    --gray-transparent: #F7F7FA20;
    --gray-900: #f7f7f7;
    --gray-900-transparent: #f7f7f720;
    --gray-800: #e1e1e1;
    --gray-800-transparent: #e1e1e120;
    --gray-700: #cfcfcf;
    --gray-700-transparent: #cfcfcf20;
    --gray-600: #b1b1b1;
    --gray-600-transparent: #b1b1b120;
    --gray-500: #9e9e9e;
    --gray-500-transparent: #9e9e9e20;
    --gray-400: #7e7e7e;
    --gray-400-transparent: #7e7e7e20;
    --gray-300: #626262;
    --gray-300-transparent: #62626220;
    --gray-200: #515151;
    --gray-200-transparent: #51515120;
    --gray-100: #3b3b3b;
    --gray-100-transparent: #3b3b3b20;
    --primary: #0066ff;
    --primary-dark: #004CAF;
    --primary-light: #75A8FF;
    --primary-transparent: #0066ff20;
    --secondary: #6C757D;
    --secondary-dark: #343A40;
    --secondary-light: #ADB5BD;
    --primary-light-300: #75839694;
    --primary-light-100: #72a2e90d;
    --secondary-transparent: #6C757D20;
    --success: #1DAB45;
    --success-dark: #1DAB45;
    --success-light: #1DAB45;
    --success-transparent: #1DAB4520;
    --success-light-100: #143a2f;
    --warning-light-100: #3a3731;
    --purple-light-100: #261b38;
    --danger: #D30808;
    --danger-dark: #B50707;
    --danger-light: #F60F0F;
    --danger-transparent: #D3080820;
    --warning: #FFB223;
    --warning-dark: #F9A200;
    --warning-light: #FFBD43;
    --warning-transparent: #FFB22320;
    --info: #0092C2;
    --info-dark: #0079A0;
    --info-light: #03ACD5;
    --info-transparent: #0092C220;
    --common-white: #e1e1e1;     // Deep Dungarani
    --white: #111727;
    --white-color: #FFFFFF;
    --black: #FFFFFF;
    --high: #7D0000;
    --medium: #B64600;
    --low: #956D00;
    --none: #007E33;
    --light: #202c3a;
    --main-content-bg-color: #202938;
    --input-border: #3e4854;
    --input-border-hover: #707b87;
    --dropdown-hover: #222939;
    --dropdown-active: #323847;
    --input-label: #8a8f95;
    --icon-color: #b0c5d7;
    --calendar-light: #2c3244;
    --transparent: #FFFFFF00;
    --placeholder-color: #868686;
    --input-text-color: #C7C7C7;
    --card-shadow: #383e4c;
    --dropdown-menu-shadow: 0px 0px 10px 1px rgb(255 255 255 / 15%);
    --green-text: #9CFFC4;
    --green-bg: #222E36;
    --red-text: #FF9898;
    --red-bg: #2C2230;
    --orange-text: #FFBD94;
    --orange-bg: #27262F;
    --yellow-text: #FFDE83;
    --yellow-bg: #252A30;
    --btn-primary: #3366ff;
    --btn-primary-dark: #2755e1;
    --btn-primary-transparent: #3366ff1a;
    --btn-secondary: #6d9cbd;
    --btn-secondary-dark: #5486a9;
    --btn-secondary-transparent: #6d9cbd1a;
    --btn-success: #157347;
    --btn-success-dark: #1b4c43;
    --btn-success-transparent: #1573471a;
    --btn-info: #717171;
    --btn-info-dark: #565e64;
    --btn-info-transparent: #7171711a;
    --btn-warning: #e2c636;
    --btn-warning-dark: #ffc310;
    --btn-warning-transparent: #e2c6361a;
    --btn-danger: #d22d3d;
    --btn-danger-dark: #ab1616;
    --btn-danger-transparent: #d22d3d1a;
    --calendar-schedule: #59419b;
    --calendar-reschedule: #138585;
    --calendar-followup: #89313f;
    --calendar-agreement: #a78c31;
    --calendar-canceled: #9e9e9e63;
    --brown-icon: #ad6359;
    --yellow-icon: #e4c556;
    --green-icon: #1ab595;
    --primary-icon: #58a4fd;
    --brown-bg-icon: #5f382f;
    --yellow-bg-icon: #89763c;
    --green-bg-icon: #254f48;
    --primary-bg-icon: #1d1d1d;
    --purple: #6046C8;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --referral-descri: #b7b7b7;
    --required-border: #0e2754;
    --required-bg: #122045;
    --wallet-th: #cccccc;
    --disabled-bgcolor: #1C2233;
    --attendance-bg: #1D1D1D;
    --myprofile-header: #1d2430;
    --package-btn-border: rgb(111 109 217);
    --tabs-arrow: #33589c;
    --userthree-counter: #0d6938;
    --release-submenu-item: #959595;
    --release-note-dis: #bebebe;
    --release-arrow: #3e3e3e;
    --release-sidebar-border: #2f2f2f;
    --package-card: #191919;
    --input-placeholder-color: #C7C7C7;
    --tabs-arrow-border: #6c6c6c;
    --package-paid-bg: #242b36;
    --total-licence-bg: #4e4e4e;
    --active-plan-date: #232323;
    --checkout-bg: #3d3d3d;
    --attendance-border: #363636;
    --home-main-title: #b9bde5de;
    --flow-content-border: #0c0c0c;
    --header-active-dark: #fff;
    --employee-highlight: #5b2929;
    --leave-type: #7e6eff99;

    --font-family-sans-serif:
        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;

    .app-release-note {
        background: linear-gradient(89.56deg, #3e3c3c -1.76%, #606060 19.27%, #3e5261 32.83%, #5f475e 69.05%, #7a6a79 88.73%);
    }

    .filepreview label.filepreviewbg img,
    .nav-item a span img {
        filter: invert(1) !important;
    }

    .bg-meetingcard {
        background-color: rgb(0, 0, 0) !important;
    }

    .referral-modal-input input {
        background-color: #393939;
    }

    .notificationcomment {
        background-color: rgb(0, 0, 0) !important;
    }

    // .notificationheading {
    //     background-color: var(--btn-primary) !important;
    // }
    .notificationheading {
        background: linear-gradient(to right, #003366 0%, #0099cc 100%);
        /* background-color: #8fabff !important; */
    }

    .notification-button {
        background-color: var(--btn-primary);
        border-color: var(--primary-transparent);
        margin-left: 0.5rem !important;
        height: 75% !important;
        border-radius: 3px;
        border: 0px !important;
        justify-content: center !important;
        display: flex;
        align-items: center;
        line-height: 1;
        color: var(--dark) !important;
        min-width: 60px !important;
    }

    .calendar-bg {
        background-color: #383838 !important;
    }

    .userrights-table tbody tr td:nth-child(2) {
        background: #494949 !important;
    }

    .table-responsive.userrights-table table tbody tr:nth-child(even) td:nth-child(2) {
        background-color: rgb(95, 94, 118) !important;
    }

    .licence-package-logo {
        filter: invert(1);
    }

    // table td:first-child {
    //     border-left: 1px solid #404040;
    // }
    // table td:last-child {
    //     border-right: 1px solid #404040;
    // }
    table td {
        // border-top: 1px solid #404040;
        border-bottom: 1px solid #404040;
    }
}

.semi-dark-color {
    --dark: #111727;
    --dark-transparent: #11172720;
    --dark-900: #1d1a49;
    --dark-900-transparent: #1d1a4920;
    --dark-800: #36345d;
    --dark-800-transparent: #36345d20;
    --dark-700: #4e4c72;
    --dark-700-transparent: #4e4c7220;
    --dark-600: #686786;
    --dark-600-transparent: #68678620;
    --dark-500: #81809a;
    --dark-500-transparent: #81809a20;
    --dark-400: #9b99ae;
    --dark-400-transparent: #9b99ae20;
    --dark-300: #b3b2c2;
    --dark-300-transparent: #b3b2c220;
    --dark-200: #cdccd7;
    --dark-200-transparent: #cdccd720;
    --dark-100: #e5e5ea;
    --dark-100-transparent: #e5e5ea20;
    --gray: #222222;
    --gray-transparent: #22222220;
    --gray-900: #3b3b3b;
    --gray-900-transparent: #3b3b3b20;
    --gray-800: #515151;
    --gray-800-transparent: #51515120;
    --gray-700: #626262;
    --gray-700-transparent: #62626220;
    --gray-600: #7e7e7e;
    --gray-600-transparent: #7e7e7e20;
    --gray-500: #9e9e9e;
    --gray-500-transparent: #9e9e9e20;
    --gray-400: #b1b1b1;
    --gray-400-transparent: #b1b1b120;
    --gray-300: #cfcfcf;
    --gray-300-transparent: #cfcfcf20;
    --gray-200: #e1e1e1;
    --gray-200-transparent: #e1e1e120;
    --gray-100: #f7f7f7;
    --gray-100-transparent: #f7f7f720;
    --primary: #0066ff;
    --primary-dark: #004CAF;
    --primary-light: #75A8FF;
    --primary-light-300: #75839694;
    --primary-light-100: #f8fafe;
    --primary-transparent: #0066ff20;
    --secondary: #6C757D;
    --secondary-dark: #343A40;
    --secondary-light: #ADB5BD;
    --secondary-transparent: #6C757D20;
    --success: #1DAB45;
    --success-dark: #1DAB45;
    --success-light: #1DAB45;
    --success-transparent: #1DAB4520;
    --purple-light-100: #f5f0fe;
    --warning-light-100: #fff5e4;
    --success-light-100: #e8f6f2;
    --danger: #D30808;
    --danger-dark: #B50707;
    --danger-light: #F60F0F;
    --danger-transparent: #D3080820;
    --warning: #FFB223;
    --warning-dark: #F9A200;
    --warning-light: #FFBD43;
    --warning-transparent: #FFB22320;
    --info: #0092C2;
    --info-dark: #0079A0;
    --info-light: #03ACD5;
    --info-transparent: #0092C220;
    --common-white: #FFFFFF;    // Deep Dungarani
    --white: #FFFFFF;
    --white-color: #FFFFFF;
    --black: #000000;
    --high: #7D0000;
    --medium: #B64600;
    --low: #956D00;
    --none: #007E33;
    --light: #e8edfa;
    --main-content-bg-color: #F9F9F9;
    --input-border: #B0B0B061;
    --input-border-hover: #c4cfdc;
    --dropdown-hover: #f1f2f9;
    --dropdown-active: #f8f9ff;
    --input-label: #79899d;
    --icon-color: #b0c5d7;
    --calendar-light: #fafafa;
    --transparent: #00000000;
    --placeholder-color: #585858;
    --input-text-color: #555555;
    --card-shadow: #cdd4e7;
    --dropdown-menu-shadow: 0px 0px 10px 1px rgb(0 0 0 / 8%);
    --green-text: #007E33;
    --green-bg: #F0FFEB;
    --red-text: #C20000;
    --red-bg: #FFEBEB;
    --orange-text: #B64600;
    --orange-bg: #FFF5EB;
    --yellow-text: #956D00;
    --yellow-bg: #FFFBDF;
    --btn-primary: #3366ff;
    --btn-primary-dark: #2755e1;
    --btn-primary-transparent: #3366ff1a;
    --btn-secondary: #6d9cbd;
    --btn-secondary-dark: #5486a9;
    --btn-secondary-transparent: #6d9cbd1a;
    --btn-success: #157347;
    --btn-success-dark: #1b4c43;
    --btn-success-transparent: #1573471a;
    --btn-info: #717171;
    --btn-info-dark: #565e64;
    --btn-info-transparent: #7171711a;
    --btn-warning: #e2c636;
    --btn-warning-dark: #ffc310;
    --btn-warning-transparent: #e2c6361a;
    --btn-danger: #d22d3d;
    --btn-danger-dark: #ab1616;
    --btn-danger-transparent: #d22d3d1a;
    --calendar-schedule: #f1f2fe;
    --calendar-reschedule: #eff6f4;
    --calendar-followup: #f5cdd3;
    --calendar-agreement: #fcf5de;
    --calendar-canceled: #9e9e9e20;
    --brown-icon: #ad6359;
    --yellow-icon: #e4c556;
    --green-icon: #1ab595;
    --primary-icon: #58a4fd;
    --brown-bg-icon: #faf6f5;
    --yellow-bg-icon: #fbf6e6;
    --green-bg-icon: #edf9f7;
    --primary-bg-icon: rgba(115, 168, 252, 0.08);
    --purple: #6046C8;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --referral-descri: #434343;
    --required-border: #d4e3ff;
    --required-bg: #ECF0FA;
    --wallet-th: #525252;
    --disabled-bgcolor: #DDDDDD;
    --attendance-bg: #FFFFFF;
    --myprofile-header: #E1EAFC;
    --package-btn-border: rgb(236, 236, 236);
    --input-placeholder-color: #00000069;
    --tabs-arrow: #051B44;
    --active-plan-shadow: #CBCFE8;
    --userthree-counter: #1EB966;
    --release-submenu-item: #585858;
    --release-note-dis: #3D3D3D;
    --release-arrow: #fff;
    --release-sidebar-border: #F1F1F1;
    --package-card: #FAFAFA;
    --tabs-arrow-border: #D8DAE5;
    --package-paid-bg: #EFF4FD;
    --total-licence-bg: #dfe8f6;
    --active-plan-date: #FAFAFA;
    --checkout-bg: #fef6e4;
    --attendance-border: #EDEDED;
    --home-main-title: #4C4E64DE;
    --flow-content-border: #DADADA;
    --header-active-dark: #5956E3;
    --employee-highlight: #fff2f2;
    --leave-type: #6d7fff;

    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

    .layout-sidebar-compact {
        .sidebar-left {
            .navigation-left {
                background: var(--dark);

                .nav-item {
                    .nav-item-hold {
                        color: var(--dark-200);
                    }
                }
            }
        }
    }

    .nav-item {
        a {
            span {
                img {
                    filter: invert(1);
                }
            }
        }
    }

    button {
        &.accordion-button {
            i {
                color: var(--gray-200);
            }
        }
    }

    .layout-sidebar-compact .sidebar-left .navigation-left .nav-item.sidebar-control a.sidebar-menu,
    .layout-sidebar-compact .sidebar-left .navigation-left .nav-item.sidebar-control a.sidebar-btn-close,
    .layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold a {
        color: var(--dark-200);
    }

    .layout-sidebar-compact .sidebar-left .navigation-left .navigation-menu-sub .nav-item:hover>.nav-item-hold,
    .layout-sidebar-compact .sidebar-left .navigation-left .navigation-menu-sub .nav-item.active>.nav-item-hold {
        color: var(--white);
    }

    .layout-sidebar-compact .sidebar-left .navigation-left .nav-item .accordion-item:hover,
    .layout-sidebar-compact .sidebar-left .navigation-left .nav-item .accordion-item.active {
        border-left: 5px solid var(--dark-100);
    }

}