.popover.clockpicker-popover > .arrow, .popover.clockpicker-popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  overflow: visible;
  margin: 0;
  padding: 0;
  z-index: auto;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  bottom: auto;
  left: auto;
  right: auto;
  top: auto;
  -webkit-transform: none;
          transform: none; }

.btn.active, .btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-default.active, .btn-default:active, .btn-default:focus, .btn-default:hover, .open .dropdown-toggle.btn-default {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad; }

.clockpicker-canvas, .clockpicker-dial {
  width: 200px;
  height: 200px;
  position: absolute;
  left: -1px;
  top: -1px; }

.popover.clockpicker-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  text-align: left;
  white-space: normal;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 6px;
  -webkit-box-shadow: 1px 1px 2px 1px #e2e2e2;
          box-shadow: 1px 1px 2px 1px #e2e2e2;
  border: 0px solid transparent; }
  .popover.clockpicker-popover.top {
    margin-top: -10px; }
    .popover.clockpicker-popover.top > .arrow {
      bottom: -11px;
      left: 50%;
      margin-left: -11px;
      border-top-color: #e6e6e6;
      border-bottom-width: 0; }
      .popover.clockpicker-popover.top > .arrow:after {
        bottom: 1px;
        margin-left: -10px;
        content: " ";
        border-top-color: #ffffff;
        border-bottom-width: 0; }
  .popover.clockpicker-popover.right {
    margin-left: 10px; }
    .popover.clockpicker-popover.right > .arrow {
      top: 50%;
      left: -11px;
      margin-top: -11px;
      border-right-color: rgba(0, 0, 0, 0.25);
      border-left-width: 0; }
      .popover.clockpicker-popover.right > .arrow:after {
        bottom: -10px;
        left: 1px;
        content: " ";
        border-right-color: #ffffff;
        border-left-width: 0; }
  .popover.clockpicker-popover.bottom {
    margin-top: 10px; }
    .popover.clockpicker-popover.bottom > .arrow {
      top: -11px;
      left: 50%;
      margin-left: -11px;
      border-top-width: 0;
      border-bottom-color: #e6e6e6; }
      .popover.clockpicker-popover.bottom > .arrow:after {
        top: 1px;
        margin-left: -10px;
        content: " ";
        border-top-width: 0;
        border-bottom-color: #ffffff; }
  .popover.clockpicker-popover.left {
    margin-left: -10px; }
    .popover.clockpicker-popover.left > .arrow {
      top: 50%;
      right: -22px;
      margin-top: -11px;
      border-right-width: 0;
      border-left-color: #e6e6e6; }
      .popover.clockpicker-popover.left > .arrow:after {
        right: 7px;
        bottom: -10px;
        content: " ";
        border-right-width: 0;
        border-left-color: #ffffff; }
  .popover.clockpicker-popover > .arrow {
    border-width: 11px; }
    .popover.clockpicker-popover > .arrow:after {
      content: "";
      border-width: 10px; }

.popover.clockpicker-popover.clockpicker-popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0; }

.popover-content {
  padding: 9px 14px; }
  .popover-content:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }

.btn {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-image: none;
  border: 1px solid transparent; }
  .btn.active:focus {
    outline: dotted thin;
    outline-offset: -2px; }
  .btn:active:focus {
    outline: dotted thin;
    outline-offset: -2px; }
  .btn:focus {
    outline: dotted thin;
    outline-offset: -2px;
    color: #333;
    text-decoration: none; }
  .btn:hover {
    color: #333;
    text-decoration: none; }

.btn-default {
  color: #333;
  background-color: #ffffff;
  border-color: #ccc; }
  .btn-default.active {
    background-image: none; }
  .btn-default:active {
    background-image: none; }

.btn-block {
  display: block;
  width: 100%; }

.clockpicker-moving {
  cursor: move; }

.popover.clockpicker-popover .popover-title {
  background-color: #fff;
  color: #999;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center; 
  padding: 8px;
}
  .popover.clockpicker-popover .popover-title span {
    cursor: pointer; }

    .popover.clockpicker-popover .popover-content {
  padding: 12px; }

.clockpicker-plate {
  background-color: #ffffff;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: visible;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.clockpicker-canvas {
  -webkit-transition: opacity 175ms;
  transition: opacity 175ms; }
  .clockpicker-canvas line {
    stroke: #4466f2;
    stroke-width: 1;
    stroke-linecap: round; }

.clockpicker-dial {
  -webkit-transition: opacity 350ms,-webkit-transform 350ms;
  transition: opacity 350ms,-webkit-transform 350ms;
  transition: transform 350ms,opacity 350ms;
  transition: transform 350ms,opacity 350ms,-webkit-transform 350ms; }

.clockpicker-minutes {
  visibility: hidden; }
  .clockpicker-minutes.clockpicker-dial-out {
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8); }

.clockpicker-tick {
  border-radius: 50%;
  color: #666;
  line-height: 26px;
  text-align: center;
  width: 26px;
  height: 26px;
  position: absolute;
  cursor: pointer; }
  .clockpicker-tick.active {
    background-color: #e1e7fd;
    color: #fff; }
  .clockpicker-tick:hover {
    color: #fff;
    background-color: #e1e7fd; }

.clockpicker-button {
  background-image: none;
  background-color: #ffffff;
  border-width: 1px 0 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  padding: 10px 0; }
  .clockpicker-button:hover {
    background-image: none;
    background-color: #ebebeb; }
  .clockpicker-button:focus {
    outline: 0 !important; }
  .clockpicker-button.am-button {
    margin: 1px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
  .clockpicker-button.pm-button {
    margin: 1px 1px 1px 136px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px; }

.clockpicker-dial-out {
  opacity: 0; }

.clockpicker-canvas-out {
  opacity: .25; }

.clockpicker-canvas-bearing {
  stroke: none;
  fill: #4466f2; }

.clockpicker-canvas-fg {
  stroke: none;
  fill: #4466f2; }

.clockpicker-canvas-bg {
  stroke: none;
  fill: #e1e7fd; }

.clockpicker-canvas-bg-trans {
  fill: #ebeffe; }

.open .dropdown-toggle.btn-default {
  background-image: none; }

.clockpicker .input-group-addon {
  cursor: pointer; }

.clockpicker-align-left.popover.clockpicker-popover > .arrow {
  left: 25px; }

.clockpicker-align-top.popover.clockpicker-popover > .arrow {
  top: 17px; }

.clockpicker-align-right.popover.clockpicker-popover > .arrow {
  left: auto;
  right: 25px; }

.clockpicker-align-bottom.popover.clockpicker-popover > .arrow {
  top: auto;
  bottom: 6px; }

.clockpicker-hours.clockpicker-dial-out {
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2); }

