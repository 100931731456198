//***** Border *******

@mixin border-solid($width, $color) {
    border: $width solid $color;
}

@mixin border-top($width, $color) {
    border-top: $width solid $color;
}

@mixin border-right($width, $color) {
    border-right: $width solid $color;
}

@mixin border-bottom($width, $color) {
    border-bottom: $width solid $color;
}

@mixin border-left($width, $color) {
    border-left: $width solid $color;
}
 
// Border Dashed
@mixin border-dashed($width, $color) {
    border: $width dashed $color;
}

@mixin border-top-dashed($width, $color) {
    border-top: $width dashed $color;
}

@mixin border-right-dashed($width, $color) {
    border-right: $width dashed $color;
}

@mixin border-bottom-dashed($width, $color) {
    border-bottom: $width dashed $color;
}

@mixin border-left-dashed($width, $color) {
    border-left: $width dashed $color;
}

// Border Dotted
@mixin border-dotted($width, $color) {
    border: $width dotted $color;
}

@mixin border-top-dotted($width, $color) {
    border-top: $width dotted $color;
}

@mixin border-right-dotted($width, $color) {
    border-right: $width dotted $color;
}

@mixin border-bottom-dotted($width, $color) {
    border-bototm: $width dotted $color;
}

@mixin border-left-dotted($width, $color) {
    border-left: $width dotted $color;
}

//******** Border Radius ********
@mixin border-radius($top, $right, $bottom, $left) {
    border-radius: $top $right $bottom $left;
}

@mixin border-tr-radius($value) {
   border-top-right-radius: $value;
}

@mixin border-tl-radius($value) {
    border-top-left-radius: $value;
}

@mixin border-bl-radius($value) {
    border-bottom-left-radius: $value;
}

@mixin border-br-radius($value) {
    border-bottom-right-radius: $value;
}
//******* Margin *********

@mixin margin($top, $right, $bottom, $left) {
    margin: $top $right $bottom $left;
}

@mixin margin-top($value) {
    margin-top: $value;
}

@mixin margin-right($value) {
    margin-right: $value;
}

@mixin margin-bottom($value) {
    margin-bottom: $value;
}

@mixin margin-left($value) {
    margin-left: $value;
}

//***** Padding *******
@mixin padding($top, $right, $bottom, $left) {
    padding: $top $right $bottom $left;
}

@mixin padding-top($value) {
    padding-top: $value;
}

@mixin padding-right($value) {
    padding-right: $value;
}

@mixin padding-bottom($value) {
    padding-bottom: $value;
}

@mixin padding-left($value) {
    padding-left: $value;
}